import React from "react";
import logo from "../../assets/images/H-MarketingLogo.jpg";
const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap justify-between items-center">
          {/* Company Information */}
          <div className="w-full sm:w-1/3 mb-6 sm:mb-0">
            <h2 className="text-xl font-bold mb-4">H-Marketing</h2>
            <p className="mb-4">
              H-Marketing is a leading provider of marketing solutions,
              dedicated to helping businesses grow and succeed in today's
              competitive marketplace.
            </p>
            <p>
              &copy; {new Date().getFullYear()} H-Marketing. All rights
              reserved.
            </p>
          </div>

          {/* Logo */}
          <div className="w-full sm:w-1/3 mb-6 sm:mb-0 flex justify-center">
            <img src={logo} alt="H-Marketing Logo" className="h-24 w-auto" />
          </div>

          {/* Contact Information */}
          <div className="w-full sm:w-1/3">
            <h2 className="text-xl font-bold mb-4">Contact Information</h2>
            <p className="mb-2">1108 E Edna Place</p>
            <p className="mb-2">Covina, CA 91701</p>
            <p>Phone: (626) 856-9856</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
