import React from "react";
import RealEstateProductPage from "../../../components/Products/Displays/RealEstateProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const RealEstatePostProductPage = () => {
  const productName = "Real Estate Post";

  // Fetch the product data by name
  const {
    data: realEstatePostProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = realEstatePostProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!realEstatePostProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <RealEstateProductPage
      name={realEstatePostProduct.name}
      productOptions={realEstatePostProduct?.productOptions}
      images={presignedUrls ?? []}
      sizes={realEstatePostProduct?.sizes}
      graphic={realEstatePostProduct?.graphic}
      rider={realEstatePostProduct?.rider}
      holesPunch={realEstatePostProduct?.holesPunch}
      path={realEstatePostProduct?.path}
      customSizing={false}
      description={`Versatile Use: Ideal for showcasing property listings with professional appeal
      Easy Assembly: PVC post and ground stake hardware set up in minutes
      Convenience: Hammer-in ground stake requires no shovel for installation
      Sign Material: Double-sided aluminum sandwich board with 1/4" rounded corners
      Durability: UV-printed for vibrant, weather-resistant outdoor use`}
    />
  );
};

export default RealEstatePostProductPage;
