import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const Magnets = () => {
  const productName = "Magnets";

  // Fetch the product data by name
  const {
    data: magnetsProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = magnetsProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!magnetsProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={magnetsProduct.name}
      images={presignedUrls ?? []}
      material={magnetsProduct?.material}
      corners={magnetsProduct?.corners}
      print={magnetsProduct?.print}
      path={magnetsProduct?.path}
      sizes={magnetsProduct?.sizes}
      customSizing={false}
      pricePerSqFt={pricePerSquareFootMap.Magnets}
      description={`30mil high-strength magnet with durable construction  
      UV direct printing ensures long-lasting performance in any weather  
      Easy to apply, remove, clean, and reapply  
      Available in custom sizes with straight or rounded corner options`}
    />
  );
};

export default Magnets;
