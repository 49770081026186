import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const TentFlagKit = () => {
  const productName = "Tent Flag Kit";

  // Fetch the product data by name
  const {
    data: eventTentProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = eventTentProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!eventTentProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={eventTentProduct.name}
      images={presignedUrls ?? []}
      flagOptions={eventTentProduct?.flagOptions}
      graphic={eventTentProduct?.graphic}
      base={eventTentProduct?.base}
      flagshapeBRsizeoption={eventTentProduct?.flagshapeBRsizeoption}
      path={eventTentProduct?.path}
      customSizing={false}
      description={`Attachment: Clamp connector easily attaches to the hex frame leg of your tent
      Compatibility: Works with Feather and Teardrop flags in Small and Medium sizes
      Printing Options: Available in single or double-sided designs
      Material: Dye-sublimated flag material for vibrant, durable performance at outdoor events`}
    />
  );
};

export default TentFlagKit;
