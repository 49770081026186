import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const EventTent = () => {
  const productName = "Event Tent (Full Color)";

  // Fetch the product data by name
  const {
    data: eventTentProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = eventTentProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!eventTentProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={eventTentProduct.name}
      productOptions={eventTentProduct?.productOptions}
      images={presignedUrls ?? []}
      reinforcedBRstrip={eventTentProduct?.reinforcedBRstrip}
      carryBag={eventTentProduct?.carryBag}
      sandbag={eventTentProduct?.sandbag}
      fullwall={eventTentProduct?.fullwall}
      halfwall={eventTentProduct?.halfwall}
      path={eventTentProduct?.path}
      customSizing={false}
      description={`10' x 10' Full-Color Custom Tent
      Frame: Heavy-duty aluminum hex frame for durability and stability
      Fabric: 6 oz weatherproof tent fabric with vibrant dye sublimation printing
      Customization Options: Optional printed walls and flags available for added branding
      Portability: Includes a standard carry bag, with an upgrade option to a premium wheeled bag for convenience`}
    />
  );
};

export default EventTent;
