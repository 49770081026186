import React from "react";
import ChannelLetterProductPage from "../../components/Products/ChannelLetters/ChannelLetterProductPage";
import { products } from "../../constants/products/products";
import { findProductByName } from "../../utils/findProductByName";

const StandardChannelLettersProductPage = () => {
  const standardChannelLetterProduct = findProductByName(
    products,
    "Standard Channel Letters"
  );

  if (!standardChannelLetterProduct) {
    // Handle the case where the product is not found
    return <div>Product not found.</div>;
  }

  return (
    <ChannelLetterProductPage
      name="Standard Channel Letter"
      images={standardChannelLetterProduct?.images}
    />
  );
};

export default StandardChannelLettersProductPage;
