import React from "react";
import SignicadeAFrameProductPage from "../../../components/Products/Displays/SignicadeAFrameProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const StandardSignicade = () => {
  const productName = "Standard Signicade";

  // Fetch the product data by name
  const {
    data: standardSignicadeProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = standardSignicadeProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!standardSignicadeProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <SignicadeAFrameProductPage
      name={standardSignicadeProduct.name}
      productOptions={standardSignicadeProduct?.productOptions}
      images={presignedUrls ?? []}
      graphic={standardSignicadeProduct?.graphic}
      frameColor={standardSignicadeProduct?.frameColor}
      path={standardSignicadeProduct?.path}
      customSizing={false}
      description={`Material: Durable white hollow-body construction for long-lasting use
      Printing Options: Single or double-sided display
      Graphics: UV-printed 24" x 36" adhesive vinyl, designed for outdoor durability
      Versatile Use: Perfect for outdoor signage in various weather conditions`}
    />
  );
};

export default StandardSignicade;
