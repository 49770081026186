import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const FoamBoard = () => {
  const productName = "Foam Board";

  // Fetch the product data by name
  const {
    data: foamBoardProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = foamBoardProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!foamBoardProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={foamBoardProduct.name}
      images={presignedUrls ?? []}
      material={foamBoardProduct?.material}
      print={foamBoardProduct?.print}
      numOfSides={foamBoardProduct?.numOfSides}
      path={foamBoardProduct?.path}
      sizes={foamBoardProduct.sizes}
      customSizing={false}
      pricePerSqFt={pricePerSquareFootMap.FoamBoard}
      description={`3/16" white foamcore with a polystyrene core and bright white surfaces  
      Direct UV printing for vivid and durable designs  
      Available in single or double-sided options  
      Lightweight yet rigid for versatile use  
      Perfect for indoor signage, framed displays, or presentation boards`}
    />
  );
};

export default FoamBoard;
