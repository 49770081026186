import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
// import ChannelLetters from "./pages/ChannelLetters/ChannelLettersProductDisplay";
import MainLayout from "./layout/MainLayout";
import AdvertisingFlags from "./pages/Displays/AdvertisingFlagsProductDisplay";
import Banners from "./pages/Displays/BannerStandProductDisplay";
import RealEstateProducts from "./pages/Displays/RealEstateProductsDisplay";
import AFrame from "./pages/Displays/AFrameProductDisplay";
import SignicadeAFrame from "./pages/Displays/SignicadeAFrameProductDisplay";
import WallArt from "./pages/Displays/WallArt";
import AdhesiveProduct from "./pages/Displays/AdhesiveProductDisplay";
import RigidSignAndMagnets from "./pages/Displays/RigidSignAndMagnetsDisplay";
import ReflectiveProduct from "./pages/Displays/ReflectiveProductDisplay";
import DryEraseProduct from "./pages/Displays/DryEraseProductDisplay";
import StandardChannelLetters from "./pages/ChannelLetters/StandardProductPage";
import HaloReverseLit from "./pages/ChannelLetters/HaloReverseLitProductPage";
import PremiumChannelLetterProductPage from "./pages/ChannelLetters/PremiumChannelLetterProductPage";
import ProductLayout from "./layout/ProductLayout";
import FeatherAngledFlag from "./pages/ProductPages/AdvertisingFlagsProductPages/FeatherAngledFlagProductPage";
import FeatherConvexFlag from "./pages/ProductPages/AdvertisingFlagsProductPages/FeatherConvexFlagProductPage";
import TeardropFlag from "./pages/ProductPages/AdvertisingFlagsProductPages/TeardropFlagProductPage";
import ReactangleFlagProductPage from "./pages/ProductPages/AdvertisingFlagsProductPages/RectangleFlagProductPage";
import EconoFeatherFlagProductPage from "./pages/ProductPages/AdvertisingFlagsProductPages/EconoFeatherFlagProductPage";
import CustomPoleFlagProductPage from "./pages/ProductPages/AdvertisingFlagsProductPages/CustomPoleFlagProductPage";
import ThirteenOzVinylBannerProductPage from "./pages/Banners/ThirteenOzVinylBannerProductPage";
import BannersProductDisplay from "./pages/Banners/BannersProductDisplay";
import EighteenOzBlockoutBannerProductPage from "./pages/Banners/EighteenOzBlockoutBannerProductPage";
import BackLitBannerProductPage from "./pages/Banners/BackLitBannerProductPage";
import IndoorBannerProductPage from "./pages/Banners/IndoorBannerProductPage";
import MeshBannerProductPage from "./pages/Banners/MeshBannerProductPage";
import { PoleBannerProductPage } from "./pages/Banners/PoleBannerProductPage";
import FabricBannerNineOzProductPage from "./pages/Banners/FabricBannerNineOzProductPage";
import FabricBannerBlockout from "./pages/Banners/FabricBannerBlockoutProductPage";
import TensionFabricProductPage from "./pages/Banners/TensionFabricProductPage";
import TensionFabricStandProductPage from "./pages/ProductPages/BannerStandProductPages/TensionFabricStandProductPage";
import { StandardRetractableProductPage } from "./pages/ProductPages/BannerStandProductPages/StandardRetractableProductPage";
import DeluxeRetractableProductPage from "./pages/ProductPages/BannerStandProductPages/DeluxeRetractableProductPage";
import SdRetractableProductPage from "./pages/ProductPages/BannerStandProductPages/SdRetractableProductPage";
import XStandProductPage from "./pages/ProductPages/BannerStandProductPages/XStandProductPage";
import StepRepeatBackdropProductPage from "./pages/ProductPages/BannerStandProductPages/StepRepeatBackdropProductPage";
import TableTopBannerStandProductPage from "./pages/ProductPages/BannerStandProductPages/TableTopBannerStandProductPage";
import YardSignHStakeProductPage from "./pages/ProductPages/RealEstateProductPages/YardSignHStakeProductPage";
import RealEstateAFrameProductPage from "./pages/ProductPages/RealEstateProductPages/RealEstateAFrameProductPage";
import RealEstatePostProductPage from "./pages/ProductPages/RealEstateProductPages/RealEstatePostProductPage";
import RealEstateFrameProductPage from "./pages/ProductPages/RealEstateProductPages/RealEstateFrameProductPage";
import BannerAFrameProductPage from "./pages/ProductPages/AFrameSignHoldersProductPages/BannerAFrameProductPage";
import PosterStand from "./pages/ProductPages/AFrameSignHoldersProductPages/PosterStand";
import MagneticWoodFrameHanger from "./pages/ProductPages/AFrameSignHoldersProductPages/MagneticWoodFrameHanger";
import StandardSignicade from "./pages/ProductPages/SignicadeAFrameProductPages/StandardSignicade";
import DeluxeSignicade from "./pages/ProductPages/SignicadeAFrameProductPages/DeluxeSignicade";
import Simposign from "./pages/ProductPages/SignicadeAFrameProductPages/Simposign";
import CanvasWrap from "./pages/ProductPages/LargeFormat/WallArtProductPages/CanvasWrap";
import FramedCanvas from "./pages/ProductPages/LargeFormat/WallArtProductPages/FramedCanvas";
import FramedPoster from "./pages/ProductPages/LargeFormat/WallArtProductPages/FramedPoster";
import AcrylicPrints from "./pages/ProductPages/LargeFormat/WallArtProductPages/AcrylicPrints";
import FramedPrints from "./pages/ProductPages/LargeFormat/WallArtProductPages/FramedPrints";
import CanvasRoll from "./pages/ProductPages/LargeFormat/WallArtProductPages/CanvasRoll";
import AdhesiveVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveVinyl";
import AdhesiveHighPerformanceVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveHighPerformanceVinyl";
import VehicleWrap from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/VehicleWrap";
import AdhesiveWindowPerf from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveWindowPerf";
import WindowCling from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/WindowCling";
import AdhesiveWallFabric from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveWallFabric";
import AdhesiveClearVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveClearVinyl";
import AdhesiveTranslucentVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/AdhesiveTranslucentVinyl";
import FloorGraphics from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/FloorGraphics";
import FrostedVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/FrostedVinyl";
import ReflectiveAdhesiveVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/ReflectiveAdhesiveVinyl";
import DryEraseAdhesiveVinyl from "./pages/ProductPages/LargeFormat/AdhesiveProductPages/DryEraseAdhesiveVinyl";
import Magnets from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/Magnets";
import Coroplast from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/Coroplast";
import AluminumSandwichBoard from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/AluminumSandwichBoard";
import Styrene from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/Styrene";
import FoamBoard from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/FoamBoard";
import GatorFoam from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/GatorFoam";
import AluminumSign from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/AluminumSign";
import ReflectiveSignicadeAFrame from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectiveSignicadeAFrame";
import ReflectiveCarMagnet from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectiveCarMagnet";
import ReflectiveCoroplast from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectiveCoroplast";
import ReflectiveAluminumSandwichBoard from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectiveAluminumSandwichBoard";
import ReflectiveAluminumSign from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectiveAluminumSign";
import ReflectivePVCBoard from "./pages/ProductPages/LargeFormat/ReflectiveProductPages/ReflectivePVCBoard";
import DryEraseSignicadeAFrame from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryEraseSignicadeAFrame";
import DryEraseMagnet from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryEraseMagnet";
import DryEraseCoroplast from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryEraseCoroplast";
import DryEraseAluminumSandwichBoard from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryEraseAluminumSandwichBoard";
import DryEraseFoamcore from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryEraseFoamcore";
import DryErasePVCBoard from "./pages/ProductPages/LargeFormat/DryEraseProductPages/DryErasePVCBoard";
import DTF from "./pages/ProductPages/LargeFormat/DTF";
import Cart from "./pages/Cart/Cart";
import Success from "./pages/Stripe/Success";
import Cancel from "./pages/Stripe/Cancel";
import CustomerOrders from "./pages/Orders/CustomerOrders";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import ContactUs from "./pages/Home/ContactUs";
import Products from "./pages/Home/Products";
import SnapPosterHangerProductPage from "./pages/ProductPages/AFrameSignHoldersProductPages/SnapPosterHangerProductPage";
import PVCBoard from "./pages/ProductPages/LargeFormat/RigidSignandMagnetsProductPages/PVCBoard";
import ChannelLettersInfo from "./components/Products/ChannelLetters/ChannelLetterInfo";
import CustomEventTentDisplay from "./pages/Displays/CustomEventTentProductDisplay";
import EventTent from "./pages/ProductPages/CustomEventTentsProductPages/EventTent";
import TentFlagKit from "./pages/ProductPages/CustomEventTentsProductPages/TentFlagKit";
import TableThrowsProductDisplay from "./pages/Displays/TableThrowsProductDisplay";
import FourFeetTableCover from "./pages/ProductPages/TableThrowProductPages/FourFeetTableCover";
import SixFeetTableCover from "./pages/ProductPages/TableThrowProductPages/SixFeetTableCover";
import EightFeetTableCover from "./pages/ProductPages/TableThrowProductPages/EightFeetTableCover";
import RoundTableCover from "./pages/ProductPages/TableThrowProductPages/RoundTableCover";
import StretchTableCover from "./pages/ProductPages/TableThrowProductPages/StretchTableCover";
import TableRunner from "./pages/ProductPages/TableThrowProductPages/TableRunner";
import SolidColorTableThrow from "./pages/ProductPages/TableThrowProductPages/SolidColorTableThrow";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) return <Loading />;

  return (
    <Routes>
      <Route path="/success" element={<Success />} />
      <Route path="/cancel" element={<Cancel />} />
      {/* Main Layout Routes */}
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/signs-letters/channel-letter"
          element={<ChannelLettersInfo />}
        />
        <Route
          path="/displays/advertising-flags"
          element={<AdvertisingFlags />}
        />
        <Route path="/displays/banner-stands" element={<Banners />} />
        <Route
          path="/displays/real-estate-products"
          element={<RealEstateProducts />}
        />
        <Route path="/displays/a-frame" element={<AFrame />} />
        <Route
          path="/displays/signicade-a-frame"
          element={<SignicadeAFrame />}
        />
        <Route
          path="/displays/custom-event-tents"
          element={<CustomEventTentDisplay />}
        />
        <Route
          path="/displays/table-throws"
          element={<TableThrowsProductDisplay />}
        />

        <Route path="/banners/all" element={<BannersProductDisplay />} />
        <Route path="/large-format/wall-art" element={<WallArt />} />
        <Route
          path="/large-format/adhesive-products"
          element={<AdhesiveProduct />}
        />
        <Route
          path="/large-format/rigid-signs-and-magnets"
          element={<RigidSignAndMagnets />}
        />
        <Route
          path="/large-format/reflective-products"
          element={<ReflectiveProduct />}
        />
        <Route
          path="/large-format/dry-erase-products"
          element={<DryEraseProduct />}
        />
      </Route>

      {/* Product Layout Routes */}
      <Route element={<ProductLayout />}>
        {/* Top Navigation Bar Routes */}
        <Route path="/cart" element={<CartRoute />} />
        <Route path="/orders" element={<OrderRoute />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact-us" element={<ContactUsRoute />} />

        {/* Channel Letter Routes */}
        <Route
          path="/standard-channel-letters"
          element={<StandardChannelLetters />}
        />
        <Route
          path="/halo-reverse-channel-letters"
          element={<HaloReverseLit />}
        />
        <Route
          path="/premium-channel-letters"
          element={<PremiumChannelLetterProductPage />}
        />

        {/* Displays Routes */}
        {/* Advertising Flag Routes */}
        <Route path="/feather-angled" element={<FeatherAngledFlag />} />
        <Route path="/feather-convex" element={<FeatherConvexFlag />} />
        <Route path="/teardrop-flag" element={<TeardropFlag />} />
        <Route path="/rectangle-flag" element={<ReactangleFlagProductPage />} />
        <Route
          path="/econo-feather"
          element={<EconoFeatherFlagProductPage />}
        />
        <Route
          path="/custom-pole-flag"
          element={<CustomPoleFlagProductPage />}
        />
        {/* Banner Stands Routes */}
        <Route
          path="/tension-fabric-stand"
          element={<TensionFabricStandProductPage />}
        />
        <Route
          path="/standard-retractable"
          element={<StandardRetractableProductPage />}
        />
        <Route
          path="/deluxe-retractable"
          element={<DeluxeRetractableProductPage />}
        />
        <Route path="/sd-retractable" element={<SdRetractableProductPage />} />
        <Route path="/x-stand" element={<XStandProductPage />} />
        <Route
          path="/step-repeat-backdrop"
          element={<StepRepeatBackdropProductPage />}
        />
        <Route
          path="/table-top-stand"
          element={<TableTopBannerStandProductPage />}
        />

        {/* Real Estate Product Routes */}
        <Route
          path="/yard-sign-h-stake"
          element={<YardSignHStakeProductPage />}
        />
        <Route
          path="/real-estate-a-frame"
          element={<RealEstateAFrameProductPage />}
        />
        <Route
          path="/real-estate-post"
          element={<RealEstatePostProductPage />}
        />
        <Route
          path="/real-estate-frame"
          element={<RealEstateFrameProductPage />}
        />

        {/* A Frame and Sign Holder Routes */}

        <Route path="/banner-a-frame" element={<BannerAFrameProductPage />} />
        <Route
          path="/snap-poster-hanger"
          element={<SnapPosterHangerProductPage />}
        />
        <Route path="/poster-stand" element={<PosterStand />} />
        <Route
          path="/magnetic-wood-frame-hanger"
          element={<MagneticWoodFrameHanger />}
        />

        {/* Signicade A Frame Routes */}

        <Route path="/standard-signicade" element={<StandardSignicade />} />
        <Route path="/deluxe-signicade" element={<DeluxeSignicade />} />
        <Route path="/simposign" element={<Simposign />} />

        {/* Event Tent Routes */}
        <Route path="/event-tent" element={<EventTent />} />
        <Route path="/tent-flag-kit" element={<TentFlagKit />} />

        {/* Table Throw Routes */}
        <Route path="/4ft-table-cover" element={<FourFeetTableCover />} />
        <Route path="/6ft-table-cover" element={<SixFeetTableCover />} />
        <Route path="/8ft-table-cover" element={<EightFeetTableCover />} />
        <Route path="/round-table-cover" element={<RoundTableCover />} />
        <Route path="/stretch-table-cover" element={<StretchTableCover />} />
        <Route path="/table-runner" element={<TableRunner />} />
        <Route
          path="/solid-color-table-throws"
          element={<SolidColorTableThrow />}
        />

        {/* Banners Routes */}
        <Route
          path="/13oz-vinyl-banner"
          element={<ThirteenOzVinylBannerProductPage />}
        />
        <Route
          path="/18oz-blockout-banner"
          element={<EighteenOzBlockoutBannerProductPage />}
        />
        <Route path="/backlit-banner" element={<BackLitBannerProductPage />} />
        <Route path="/mesh-banner" element={<MeshBannerProductPage />} />
        <Route path="/indoor-banner" element={<IndoorBannerProductPage />} />
        <Route path="/pole-banner" element={<PoleBannerProductPage />} />
        <Route
          path="/fabric-banner-9oz"
          element={<FabricBannerNineOzProductPage />}
        />
        <Route
          path="/fabric-banner-9.5oz-blockout"
          element={<FabricBannerBlockout />}
        />
        <Route path="/tension-fabric" element={<TensionFabricProductPage />} />

        {/* Large Format Routes */}
        {/* Wall Art Routes */}
        <Route path="/canvas-wrap" element={<CanvasWrap />} />
        <Route path="/framed-canvas" element={<FramedCanvas />} />
        <Route path="/framed-poster" element={<FramedPoster />} />
        <Route path="/acrylic-prints" element={<AcrylicPrints />} />
        <Route path="/wood-frame" element={<MagneticWoodFrameHanger />} />
        <Route path="/framed-prints" element={<FramedPrints />} />
        <Route path="/canvas-roll" element={<CanvasRoll />} />

        {/* Adhesive Product Routes */}
        <Route path="/adhesive-vinyl" element={<AdhesiveVinyl />} />
        <Route
          path="/performance-vinyl"
          element={<AdhesiveHighPerformanceVinyl />}
        />
        <Route path="/vehicle-wrap" element={<VehicleWrap />} />
        <Route path="/adhesive-window-perf" element={<AdhesiveWindowPerf />} />
        <Route path="/window-cling" element={<WindowCling />} />
        <Route path="/adhesive-wall-fabric" element={<AdhesiveWallFabric />} />
        <Route path="/adhesive-clear-vinyl" element={<AdhesiveClearVinyl />} />
        <Route
          path="/adhesive-translucent-vinyl"
          element={<AdhesiveTranslucentVinyl />}
        />
        <Route path="/floor-graphics" element={<FloorGraphics />} />
        <Route path="/frosted-vinyl" element={<FrostedVinyl />} />
        <Route
          path="/reflective-adhesive-vinyl"
          element={<ReflectiveAdhesiveVinyl />}
        />
        <Route
          path="/dry-erase-adhesive-vinyl"
          element={<DryEraseAdhesiveVinyl />}
        />

        {/* Rigid Sign and Magnets Product Routes */}
        <Route path="/magnets" element={<Magnets />} />
        <Route path="/coroplast" element={<Coroplast />} />
        <Route
          path="/aluminum-sandwich-board"
          element={<AluminumSandwichBoard />}
        />
        <Route path="/pvc-board" element={<PVCBoard />} />
        <Route path="/styrene" element={<Styrene />} />
        <Route path="/foam-board" element={<FoamBoard />} />
        <Route path="/gator-foam" element={<GatorFoam />} />
        <Route path="/aluminum-sign" element={<AluminumSign />} />

        {/* Reflective Product Routes */}
        <Route
          path="/reflective-signicade-aframe"
          element={<ReflectiveSignicadeAFrame />}
        />
        <Route
          path="/reflective-car-magnet"
          element={<ReflectiveCarMagnet />}
        />
        <Route path="/reflective-coroplast" element={<ReflectiveCoroplast />} />
        <Route
          path="/reflective-aluminum-sandwich-board"
          element={<ReflectiveAluminumSandwichBoard />}
        />
        <Route
          path="/reflective-aluminum-sign"
          element={<ReflectiveAluminumSign />}
        />
        <Route path="/reflective-pvc-board" element={<ReflectivePVCBoard />} />

        {/* Dry Erase Product Routes */}
        <Route
          path="/dry-erase-adhesive-vinyl"
          element={<DryEraseAdhesiveVinyl />}
        />
        <Route
          path="/dry-erase-signicade-aframe"
          element={<DryEraseSignicadeAFrame />}
        />
        <Route path="/dry-erase-magnet" element={<DryEraseMagnet />} />
        <Route path="/dry-erase-coroplast" element={<DryEraseCoroplast />} />
        <Route
          path="/dry-erase-aluminum-sandwich-board"
          element={<DryEraseAluminumSandwichBoard />}
        />
        <Route path="/dry-erase-foamcore" element={<DryEraseFoamcore />} />
        <Route path="/dry-erase-pvc-board" element={<DryErasePVCBoard />} />

        {/* Other Large Format Product Routes */}
        <Route path="/dtf" element={<DTF />} />
      </Route>
    </Routes>
  );
};

const OrderRoute = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <CustomerOrders /> : <Navigate to="/" replace />;
};

const CartRoute = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <Cart /> : <Navigate to="/" replace />;
};

const ContactUsRoute = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <ContactUs /> : <Navigate to="/" replace />;
};

export default App;
