import React from "react";
import BannerStandsProductPage from "../../../components/Products/Displays/BannerStandsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

export const StandardRetractableProductPage = () => {
  const productName = "Standard Retractable";

  // Fetch the product data by name
  const {
    data: standardRetractableProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = standardRetractableProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!standardRetractableProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannerStandsProductPage
      name={standardRetractableProduct.name}
      productOptions={standardRetractableProduct?.productOptions}
      images={presignedUrls ?? []}
      sizes={standardRetractableProduct?.sizes}
      insert={standardRetractableProduct?.insert}
      LEDlight={standardRetractableProduct?.LEDlight}
      path={standardRetractableProduct?.path}
      customSizing={false}
      description={`Graphics: UV-printed fabric with curl-free edges for a polished appearance
      Hardware: Lightweight aluminum frame with two stabilizing feet for added support
      Easy Assembly: Designed for quick and hassle-free setup
      Portability: Comes with a complimentary travel bag for convenient transport and storage`}
    />
  );
};
