import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { removeFromCart } from "../../store/cartSlice";
import CartItem from "../../components/Cart/CartItem";
import StripeContainer from "../../components/Stripe/CheckoutButton";
import { Link } from "react-router-dom";
import { EmptyCartIcon } from "../../assets/icons/icons";
import FileUploadInformation from "../../components/Products/FileUploadInformation";

const Cart: React.FC = () => {
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const dispatch = useDispatch();

  const totalPrice = cartItems.reduce((total, item) => total + item.total, 0);

  const allArtworkUploaded = cartItems.every(
    (item) => item.productArtwork && item.productArtwork.trim() !== ""
  );

  const handleRemoveFromCart = (index: number) => {
    dispatch(removeFromCart(index));
  };

  return (
    <div className="w-full max-w-7xl mx-auto py-12 grid grid-cols-1 md:grid-cols-3 gap-8">
      {cartItems.length > 0 ? (
        <>
          {/* Center Column: Cart Items */}
          <div className="col-span-1 md:col-span-2">
            <div className="grid gap-8">
              <div className="grid gap-4">
                <h1 className="text-2xl font-bold tracking-tight text-center md:text-left">
                  Your Cart
                </h1>
                {!allArtworkUploaded && (
                  <div className="mt-2 bg-red-100 border-l-4 border-red-500 text-red-700 p-4">
                    <p>
                      Please upload all product artwork to checkout. Items
                      without artwork requirements will not block checkout.
                    </p>
                  </div>
                )}
                <div className="grid gap-6">
                  {cartItems.map((item, index) => (
                    <CartItem
                      key={index}
                      item={item}
                      index={index}
                      onRemove={() => handleRemoveFromCart(index)}
                    />
                  ))}
                </div>
              </div>
              <div className="grid gap-4 border-t pt-4">
                <div className="flex items-center justify-between">
                  <span className="font-semibold">Total</span>
                  <span className="text-2xl font-bold">
                    ${totalPrice.toFixed(2)}
                  </span>
                </div>
                <StripeContainer cartItems={cartItems} />
              </div>
            </div>
          </div>

          {/* Right Column: File Upload Information */}
          <div className="hidden md:block">
            <FileUploadInformation />
          </div>
        </>
      ) : (
        <div className="col-span-3 flex flex-col items-center justify-center space-y-6 text-center">
          <EmptyCartIcon className="w-24 h-24 text-gray-400" />
          <h1 className="text-3xl font-bold text-gray-800">
            Your Cart is Empty
          </h1>
          <p className="text-gray-600">
            It looks like you haven't added any items to your cart yet.
          </p>
          <Link
            to="/products"
            className="bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded transition duration-200"
          >
            Continue Shopping
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
