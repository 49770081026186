import React, { useState, useEffect } from "react";

interface Dimensions {
  heightFt: number;
  heightIn: number;
  widthFt: number;
  widthIn: number;
}

interface CustomSizeInputProps {
  onSizeChange: (
    squareFeet: number,
    additionalCost: number,
    dimensions: Dimensions
  ) => void;
  pricePerSqFt: number;
  maxDimensions?: Dimensions; // Optional max dimensions for height and width
}

const CustomSizeInput: React.FC<CustomSizeInputProps> = ({
  onSizeChange,
  pricePerSqFt,
  maxDimensions,
}) => {
  const [heightFt, setHeightFt] = useState(0);
  const [heightIn, setHeightIn] = useState(0);
  const [widthFt, setWidthFt] = useState(0);
  const [widthIn, setWidthIn] = useState(0);
  const [squareFeet, setSquareFeet] = useState(0);

  useEffect(() => {
    const calculateSquareFeet = () => {
      const totalHeightInches = heightFt * 12 + heightIn;
      const totalWidthInches = widthFt * 12 + widthIn;
      const totalSquareFeet = (totalHeightInches * totalWidthInches) / 144;
      setSquareFeet(totalSquareFeet);
      const newAdditionalCost = totalSquareFeet * pricePerSqFt;
      onSizeChange(totalSquareFeet, newAdditionalCost, {
        heightFt,
        heightIn,
        widthFt,
        widthIn,
      });
    };

    calculateSquareFeet();
  }, [heightFt, heightIn, widthFt, widthIn, onSizeChange, pricePerSqFt]);

  const handleNonNegativeChange = (value: number) => Math.max(0, value);

  const handleHeightFtChange = (value: number) => {
    const validatedValue = handleNonNegativeChange(value);

    if (maxDimensions && validatedValue >= maxDimensions.heightFt) {
      // If heightFt reaches max, set heightIn to 0
      setHeightFt(maxDimensions.heightFt);
      setHeightIn(0);
    } else {
      setHeightFt(validatedValue);
    }
  };

  const handleHeightInChange = (value: number) => {
    if (heightFt >= (maxDimensions?.heightFt ?? Infinity)) {
      // Restrict heightIn if heightFt is at max
      setHeightIn(0);
    } else {
      const validatedValue = handleNonNegativeChange(value);
      setHeightIn(validatedValue >= 12 ? 11 : validatedValue); // Limit to 11 inches
    }
  };

  const handleWidthFtChange = (value: number) => {
    const validatedValue = handleNonNegativeChange(value);

    if (maxDimensions && validatedValue >= maxDimensions.widthFt) {
      // If widthFt reaches max, set widthIn to 0
      setWidthFt(maxDimensions.widthFt);
      setWidthIn(0);
    } else {
      setWidthFt(validatedValue);
    }
  };

  const handleWidthInChange = (value: number) => {
    if (widthFt >= (maxDimensions?.widthFt ?? Infinity)) {
      // Restrict widthIn if widthFt is at max
      setWidthIn(0);
    } else {
      const validatedValue = handleNonNegativeChange(value);
      setWidthIn(validatedValue >= 12 ? 11 : validatedValue); // Limit to 11 inches
    }
  };

  return (
    <div className="p-4 bg-white shadow-sm rounded-lg">
      <h3 className="text-lg font-semibold text-gray-800 mb-4">
        Enter Custom Size:
      </h3>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Height:
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={heightFt}
              onChange={(e) => handleHeightFtChange(+e.target.value)}
              placeholder="Feet"
              min="0"
              max={maxDimensions?.heightFt ?? undefined}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">ft</span>
            <input
              type="number"
              value={heightIn}
              onChange={(e) => handleHeightInChange(+e.target.value)}
              placeholder="Inches"
              min="0"
              max="11"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">in</span>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Width:
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={widthFt}
              onChange={(e) => handleWidthFtChange(+e.target.value)}
              placeholder="Feet"
              min="0"
              max={maxDimensions?.widthFt ?? undefined}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">ft</span>
            <input
              type="number"
              value={widthIn}
              onChange={(e) => handleWidthInChange(+e.target.value)}
              placeholder="Inches"
              min="0"
              max="11"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="text-sm font-medium text-gray-700">in</span>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-800">
          Square Feet: {squareFeet.toFixed(2)} ft&sup2;
        </p>
      </div>
    </div>
  );
};

export default CustomSizeInput;
