import { useInView } from "react-intersection-observer";

interface ProductCardProps {
  product: any;
  imageUrl: string | undefined;
  handleProductClick: (path: string) => void;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  imageUrl,
  handleProductClick,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className="flex flex-col bg-white rounded-lg shadow overflow-hidden cursor-pointer h-full"
      onClick={() => handleProductClick(product.path)}
    >
      {inView ? (
        imageUrl ? (
          <img
            src={imageUrl}
            alt={product.name}
            className="w-full h-80 object-cover transition-transform duration-500 hover:scale-110"
          />
        ) : (
          <SkeletonLoader />
        )
      ) : (
        <SkeletonLoader />
      )}
      <div className="flex-1 flex flex-col p-6 justify-between">
        <div>
          <h2 className="text-xl font-semibold mb-1">{product.name}</h2>
        </div>
        <div className="border-t border-gray-200 pt-4 mt-4">
          <button
            onClick={() => handleProductClick(product.path)}
            className="inline-block w-full bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded text-center"
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="w-full h-80 bg-gray-200 animate-pulse"></div>
);
