import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";
import { ProductProps } from "../../interface/product/Product";

// Function to fetch product by name from the API
const fetchProductByName = async (
  productName: string
): Promise<ProductProps> => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const response = await axios.get(`${apiUrl}/api/product/product-by-name`, {
    params: { name: productName }, // Send the product name as query param
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

// React Query hook to fetch product by name, with additional options parameter
const useProductByName = (
  productName: string,
  options: object = {} // Add options argument with a default empty object
): UseQueryResult<ProductProps, AxiosError> => {
  return useQuery(
    ["productByName", productName], // The query key depends on the product name
    () => fetchProductByName(productName), // Query function
    {
      enabled: !!productName, // Only run the query if productName is truthy (non-empty)
      keepPreviousData: true, // Maintain previous data to avoid flickering
      ...options, // Merge user-defined options with defaults
    }
  );
};

export default useProductByName;
