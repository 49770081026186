import { useMutation, UseMutationResult } from "react-query";
import axios, { AxiosError } from "axios";
import { useAuth0 } from "@auth0/auth0-react";

interface CheckoutSessionResponse {
  id: string;
}

interface CartItem {
  product: string;
  productImg?: string;
  options: Record<string, any>;
  total: number;
}

const useCreateCheckoutSession = (): UseMutationResult<
  CheckoutSessionResponse,
  Error,
  CartItem[]
> => {
  const { getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;

  const mutation = useMutation<CheckoutSessionResponse, Error, CartItem[]>(
    async (cartItems) => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${apiUrl}/api/stripe/create-checkout-session`,
          {
            items: cartItems,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        const axiosError = error as AxiosError;
        throw new Error(
          axiosError.message ||
            "An error occurred while creating checkout session"
        );
      }
    }
  );

  return mutation;
};

export default useCreateCheckoutSession;
