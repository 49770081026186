import React from "react";

interface Option {
  name: string;
  price?: number;
}

interface OptionSectionProps {
  title: string;
  options: Option[];
  selected: Option;
  setSelected: (option: Option) => void;
  displayType?: "card" | "dropdown";
}

const OptionSection: React.FC<OptionSectionProps> = ({
  title,
  options,
  selected,
  setSelected,
  displayType = "card",
}) => {
  return (
    <div className="mb-4">
      <h2 className="text-xl font-semibold mb-3">{title}</h2>
      {displayType === "dropdown" && options.length > 1 ? (
        <div className="relative">
          <select
            value={selected.name}
            onChange={(e) =>
              setSelected(
                options.find((option) => option.name === e.target.value) ??
                  options[0]
              )
            }
            className="block appearance-none w-full bg-white border border-gray-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            {options.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
            </svg>
          </div>
        </div>
      ) : displayType === "dropdown" && options.length === 1 ? (
        <div className="p-4 border rounded-lg border-gray-300 bg-gray-50 flex items-center justify-between">
          <p className="text-gray-900 font-medium">{options[0].name}</p>
          {/* <p className="text-gray-600">
            ${options[0].price?.toFixed(2) ?? "N/A"}
          </p> */}
        </div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {options.map((option) => (
            <div
              key={option.name}
              className={`p-4 border rounded-lg ${
                selected.name === option.name
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300"
              } cursor-pointer transition duration-300 ease-in-out hover:bg-blue-100`}
              onClick={() => setSelected(option)}
            >
              <div className="text-center">
                <p className="text-gray-900 font-medium">{option.name}</p>
                {/* <p className="text-gray-600">
                  ${option.price?.toFixed(2) ?? "N/A"}
                </p> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionSection;
