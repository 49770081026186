import React from "react";
import TopNavigationBar from "../components/Home/TopNavigationBar";
import { UserProvider } from "../context/UserContext";
import { Outlet } from "react-router-dom";
import Footer from "../components/Home/Footer";

const ProductLayout: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <UserProvider>
        <TopNavigationBar />
      </UserProvider>
      <div className="flex flex-1 overflow-hidden">
        <main className="flex-1 p-4 overflow-auto bg-gray-100">
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default ProductLayout;
