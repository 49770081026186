import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const VehicleWrap = () => {
  const productName = "3M IJ-180Cv3 Controltac (Vehicle Wrap)";

  // Fetch the product data by name
  const {
    data: vehicleWrapProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = vehicleWrapProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!vehicleWrapProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={vehicleWrapProduct.name}
      images={presignedUrls ?? []}
      print={vehicleWrapProduct?.print}
      material={vehicleWrapProduct?.material}
      lamination={vehicleWrapProduct?.lamination}
      path={vehicleWrapProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.Controltac}
      description={`3M IJ180CV3 vehicle wrap cast vinyl with 3M 8518 gloss overlaminate
      Air release backing ensures bubble-free installation
      Flexible, slideable, and repositionable film with medium tack for easy application
      Eco-solvent printed for vibrant, durable graphics suitable for indoor and outdoor use`}
    />
  );
};

export default VehicleWrap;
