import React from "react";

interface OrderDetailsProps {
  jobName: string;
  setJobName: (name: string) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  storePickup: boolean;
  setStorePickup: (pickup: boolean) => void;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  jobName,
  setJobName,
  quantity,
  setQuantity,
  storePickup,
  setStorePickup,
}) => (
  <div className="p-4 bg-white shadow-sm rounded-lg">
    <div className="mb-4">
      <label
        htmlFor="jobName"
        className="block text-sm font-medium text-gray-700"
      >
        Job Name
      </label>
      <input
        type="text"
        id="jobName"
        value={jobName}
        onChange={(e) => setJobName(e.target.value)}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="quantity"
        className="block text-sm font-medium text-gray-700"
      >
        Quantity
      </label>
      <input
        type="number"
        id="quantity"
        value={quantity}
        onChange={(e) => setQuantity(Number(e.target.value))}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        min="1"
      />
    </div>
    <div className="flex items-center mb-4">
      <button
        onClick={() => setStorePickup(!storePickup)}
        className={`${
          storePickup ? "bg-deep-blue" : "bg-gray-200"
        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
      >
        <span
          className={`${
            storePickup ? "translate-x-6" : "translate-x-1"
          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
        />
      </button>
      <span className="ml-3 text-sm font-medium text-gray-700">
        Store Pickup (Covina, CA)
      </span>
    </div>
  </div>
);

export default OrderDetails;
