import React from "react";

export const MobileNavigationLink = (Icon: any, text: string) => (
  <a
    href="/"
    className="text-white  hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
  >
    <Icon className="w-6 h-6 mr-2" />
    {text}
  </a>
);
