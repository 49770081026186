// File: src/components/ChannelLettersInfo.tsx

import React from "react";
import { useNavigate } from "react-router-dom";

const ChannelLettersInfo: React.FC = () => {
  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate("/contact-us");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="px-4 py-8 bg-gray-50">
      <div className="text-center mb-6">
        <h1 className="text-3xl font-bold mb-2 text-gray-800">
          Learn About Channel Letters
        </h1>
        <p className="text-lg text-gray-600">
          For more information involving Channel Letters, please log in and
          submit a contact us form.
        </p>
      </div>
      <div className="text-center">
        <button
          onClick={handleContactUsClick}
          className="inline-block bg-medium-gold hover:bg-dark-gold text-white font-bold py-3 px-6 rounded-lg shadow"
        >
          Go to Contact Us
        </button>
      </div>
    </div>
  );
};

export default ChannelLettersInfo;
