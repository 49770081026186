import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ShoppingCartIcon } from "../../assets/icons/icons";

const CartIcon: React.FC = () => {
  const cartItemsCount = useSelector(
    (state: RootState) => state.cart.items.length
  );

  return (
    <div className="relative">
      <ShoppingCartIcon className="h-6 w-6 text-white" />
      {cartItemsCount > 0 && (
        <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
          {cartItemsCount}
        </span>
      )}
    </div>
  );
};

export default CartIcon;
