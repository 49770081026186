import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";
import { ProductProps } from "../../interface/product/Product";

const fetchAllProducts = async (): Promise<ProductProps[]> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  // const token = await getAccessTokenSilently();

  const response = await axios.get(`${apiUrl}/api/product/all-products`, {
    headers: {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useAllProducts = (): UseQueryResult<ProductProps[], AxiosError> => {
  // const { getAccessTokenSilently } = useAuth0();

  return useQuery("allProducts", () => fetchAllProducts(), {
    keepPreviousData: true,
    staleTime: 600000,
    onError: (error) => {
      console.error("Failed to fetch all products:", error.message);
    },
  });
};

export default useAllProducts;
