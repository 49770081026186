import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const AdhesiveTranslucentVinyl = () => {
  const productName = "Adhesive Translucent Vinyl";

  // Fetch the product data by name
  const {
    data: adhesiveTranslucentVinylProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = adhesiveTranslucentVinylProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!adhesiveTranslucentVinylProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={adhesiveTranslucentVinylProduct.name}
      images={presignedUrls ?? []}
      colorProfile={adhesiveTranslucentVinylProduct?.colorProfile}
      material={adhesiveTranslucentVinylProduct?.material}
      print={adhesiveTranslucentVinylProduct?.print}
      lamination={adhesiveTranslucentVinylProduct?.lamination}
      path={adhesiveTranslucentVinylProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.AdhesiveTranslucentVinyl}
      description={`3 mil translucent vinyl designed for light boxes  
      2.5 mil matte overlaminate provides protection for prints  
      Adhesive backing adheres to Lexan, acrylic, and other illuminated sign surfaces  
      Durable for both indoor and outdoor applications`}
    />
  );
};

export default AdhesiveTranslucentVinyl;
