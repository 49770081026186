import React from "react";
import AFrameProductPage from "../../../components/Products/Displays/AFrameProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const MagneticWoodFrameHanger = () => {
  const productName = "Magnetic Wood Frame Hanger";

  // Fetch the product data by name
  const {
    data: magneticWoodFrameHangerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = magneticWoodFrameHangerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!magneticWoodFrameHangerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <AFrameProductPage
      name={magneticWoodFrameHangerProduct.name}
      images={presignedUrls ?? []}
      sizes={magneticWoodFrameHangerProduct?.sizes}
      graphic={magneticWoodFrameHangerProduct?.graphic}
      hanger={magneticWoodFrameHangerProduct?.hanger}
      path={magneticWoodFrameHangerProduct?.path}
      customSizing={false}
      description={`Natural wood poster hangers paired with a vibrant full-color UV-printed graphic  
      Top and bottom hangers securely snap together with strong magnets  
      Designed for effortless hanging from a single nail`}
    />
  );
};

export default MagneticWoodFrameHanger;
