import React from "react";
import AdvertisingFlagsProductPage from "../../../components/Products/Displays/AdvertisingFlagsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../constants/products/pricePerSquareFT";

const CustomPoleFlagProductPage = () => {
  const productName = "Custom Pole Flag";

  // Fetch the product data by name
  const {
    data: customPoleFlagProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = customPoleFlagProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!customPoleFlagProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <AdvertisingFlagsProductPage
      name={customPoleFlagProduct.name}
      images={presignedUrls ?? []}
      flagOptions={customPoleFlagProduct.flagOptions}
      customSizing={true}
      graphic={customPoleFlagProduct.graphic}
      // finishing={customPoleFlagProduct.finishing}
      bracket={customPoleFlagProduct.bracket}
      path={customPoleFlagProduct.path}
      pricePerSqFt={pricePerSquareFootMap.CustomPoleFlag}
      description={`Printing Options: Single or double-sided, full-color dye sublimation on durable 4 oz polyester fabric
      Custom Sizes: Available in widths and heights up to 62" x 100'
      Design Features: Grommet strip included to enhance flag durability and extend its lifetime
      Extras: Optional pole and wall bracket available for house flag displays`}
    />
  );
};

export default CustomPoleFlagProductPage;
