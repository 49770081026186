import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import useCustomerOrders from "../../hooks/orders/useCustomerOrders";
import { Order } from "../../interface/orders/order";
import useSubmitContactUsForm from "../../hooks/user/useSubmitContactUsForm";
import Loading from "../../components/Loading";

const ContactUs: React.FC = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth0();
  const [category, setCategory] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [message, setMessage] = useState("");
  const [orders, setOrders] = useState<Order[]>([]);
  const {
    data: ordersData,
    isLoading,
    isError,
  } = useCustomerOrders(1, 100, "new", "");
  const submitContactUsForm = useSubmitContactUsForm();

  useEffect(() => {
    if (category === "existing orders" && ordersData) {
      setOrders(ordersData.orders);
    }
  }, [category, ordersData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isAuthenticated) {
      toast.error("You must be logged in to submit the form");
      return;
    }

    try {
      await submitContactUsForm.mutateAsync({
        name: user?.name || "",
        email: user?.email || "",
        category,
        orderId: selectedOrder,
        message,
      });
      toast.success("Your message has been sent!");
      setCategory("");
      setSelectedOrder("");
      setMessage("");
      navigate("/");
    } catch (error) {
      toast.error("Form could not be submitted at this time");
      console.error("Error sending message:", error);
    }
  };

  if (isLoading) return <Loading />;
  if (isError) return <div>{isError}</div>;

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 font-medium mb-2"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={user?.name || ""}
            disabled
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="category"
            className="block text-gray-700 font-medium mb-2"
          >
            Category
          </label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select a category</option>
            <option value="pricing">Pricing</option>
            <option value="bulk orders">Bulk Orders</option>
            <option value="existing orders">Existing Orders</option>
          </select>
        </div>
        {category === "existing orders" && (
          <div className="mb-4">
            <label
              htmlFor="order"
              className="block text-gray-700 font-medium mb-2"
            >
              Order ID
            </label>
            <select
              id="order"
              value={selectedOrder}
              onChange={(e) => setSelectedOrder(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select an order</option>
              {orders.map((order: any) => (
                <option key={order.orderId} value={order.orderId}>
                  {order.orderId}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="message"
            className="block text-gray-700 font-medium mb-2"
          >
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            rows={4}
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
