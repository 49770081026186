import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cartSlice"; // Adjust the import path

interface CartButtonProps {
  product: {
    product: string;
    options: Record<string, any>;
    total: number;
  };
}

const AddToCartButton: React.FC<CartButtonProps> = ({ product }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const [showAnimation, setShowAnimation] = useState(false);

  const handleAddToCart = () => {
    dispatch(addToCart(product));
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  };

  return (
    <div className="mt-4">
      <h2 className="text-2xl font-bold">Total: ${product.total.toFixed(2)}</h2>
      {isAuthenticated ? (
        <button
          onClick={handleAddToCart}
          className="mt-4 bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded disabled:bg-gray-400"
          disabled={product.total === 0} // Optionally disable if no options affect the price
        >
          Add to Cart
        </button>
      ) : (
        <button
          onClick={() => loginWithRedirect()}
          className="mt-4 bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded"
        >
          Sign In
        </button>
      )}
      {showAnimation && (
        <div className="fixed top-4 right-4 bg-dark-gold text-white p-2 rounded shadow-lg z-50 animate-fadeInOut">
          Item added to cart!
        </div>
      )}
    </div>
  );
};

export default AddToCartButton;
