import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const DryEraseCoroplast = () => {
  const productName = "Dry Erase Coroplast";

  // Fetch the product data by name
  const {
    data: dryEraseCoroplastProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = dryEraseCoroplastProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!dryEraseCoroplastProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={dryEraseCoroplastProduct.name}
      images={presignedUrls ?? []}
      numOfSides={dryEraseCoroplastProduct?.numOfSides}
      grommet={dryEraseCoroplastProduct?.grommet}
      lamination={dryEraseCoroplastProduct?.lamination}
      material={dryEraseCoroplastProduct?.material}
      path={dryEraseCoroplastProduct?.path}
      sizes={dryEraseCoroplastProduct?.sizes}
      customSizing={false}
      pricePerSqFt={pricePerSquareFootMap.DryEraseCoroplast}
      description={`4mm corrugated plastic sign material with UV-printed graphics and a UV-cured glossy dry erase coating  
      Custom sizes available with options for grommets or H-stakes  
      Offered in single or double-sided printing configurations for versatile use`}
    />
  );
};

export default DryEraseCoroplast;
