import React, { useState } from "react";
import {
  FaqProps,
  ProductDetailProps,
  TabContentProps,
} from "../../interface/product/ProductDetails";

const ProductDetails: React.FC<ProductDetailProps> = ({ details }) => {
  // const [activeTab, setActiveTab] = useState<
  //   "description" | "breakdown" | "warranty" | "faq"
  // >("description");

  const [activeTab, setActiveTab] = useState<
    "description" | "returns" | "artwork"
  >("description");

  const TabContent: React.FC<TabContentProps> = ({ content }) => {
    // Split content into lines
    const lines = content.split("\n").map((line) => line.trim());

    return (
      <div className="p-4 bg-white rounded-lg shadow-sm">
        <ul className="list-disc list-inside text-gray-600 space-y-2">
          {lines.map((line, index) => (
            <li key={index} className="text-sm font-medium">
              {line}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const FAQContent: React.FC<FaqProps> = ({ faq }) => (
    <div>
      {faq.map((item, index) => (
        <div key={index} className="mt-2">
          <h4 className="font-semibold">{item.question}</h4>
          <p>{item.answer}</p>
        </div>
      ))}
    </div>
  );

  const getContent = () => {
    switch (activeTab) {
      case "description":
        return <TabContent content={details.description} />;
      case "returns":
        return (
          <TabContent
            content={`All sales are final. We do not accept returns or provide refunds for any purchases.
        For any questions or concerns about your order, please fill out our Contact Us form, and a member of our team will be happy to assist you`}
          />
        );
      case "artwork":
        return (
          <TabContent
            content={`File Formats: Accepted formats are JPEG or PDF (single-page only).
            Color Space: Use CMYK for accurate color reproduction.
            Resolution: Raster images should be 150 dpi, sufficient for large format printing.
            File Size: Maximum upload size is 300MB.
            Artwork Size: Submit artwork at the exact size of your order. Scaled artwork will be automatically detected and adjusted.
            Crop Marks/Bleeds: Do not include crop marks or bleeds in your file.
            Double-Sided Products: Provide two separate files for double-sided designs unless the artwork template specifies otherwise.`}
          />
        );
      // case "warranty":
      //   return <TabContent content={details.warranty} />;
      // case "faq":
      //   return <FAQContent faq={details.faq} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Product Details
      </h2>
      <ul className="flex border-b">
        {["description", "returns", "artwork"].map((key) => (
          <li
            key={key}
            className={`mr-6 ${
              activeTab === key ? "border-b-2 border-blue-500" : ""
            }`}
          >
            <button
              className="text-gray-800 font-semibold py-2 px-4 focus:outline-none"
              onClick={() => setActiveTab(key as any)}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </button>
          </li>
        ))}
      </ul>
      <div className="mt-4">{getContent()}</div>
    </div>
  );
};

export default ProductDetails;
