import React from "react";
import BannersProductPage from "../../components/Banners/BannersProductPage";
import useProductByName from "../../hooks/product/useProductByName";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";

const EighteenOzBlockoutBannerProductPage = () => {
  const productName = "18oz Blockout Banner";

  // Fetch the product data by name
  const {
    data: eighteenOzBlockoutBannerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = eighteenOzBlockoutBannerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!eighteenOzBlockoutBannerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannersProductPage
      name="18oz Blockout Banner"
      images={presignedUrls ?? []}
      numOfSides={eighteenOzBlockoutBannerProduct?.numOfSides}
      material={eighteenOzBlockoutBannerProduct?.material}
      polePocket={eighteenOzBlockoutBannerProduct?.polePocket}
      hem={eighteenOzBlockoutBannerProduct?.hem}
      grommet={eighteenOzBlockoutBannerProduct?.grommet}
      windslit={eighteenOzBlockoutBannerProduct?.windslit}
      webbing={eighteenOzBlockoutBannerProduct?.webbing}
      // corners={eighteenOzBlockoutBannerProduct?.corners}
      path={eighteenOzBlockoutBannerProduct?.path}
      rope={eighteenOzBlockoutBannerProduct?.rope}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.EighteenozVinylBanner}
      description={`18 oz matte blockout banner  
        UV full-color printing for vibrant, durable designs  
        Suitable for both indoor and outdoor use  
        Option for single or double-sided printing with designs on both sides  
        Complimentary hemming and grommets included  
        Extra finishing options offered  
        Oversized banners with welded edges available`}
    />
  );
};

export default EighteenOzBlockoutBannerProductPage;
