import React from "react";

const FileUploadInformation = () => {
  return (
    <div className="bg-deep-blue p-6 rounded-lg shadow-lg text-white">
      <h2 className="text-xl font-bold mb-2 text-center">
        Artwork Requirements
      </h2>
      <div className="grid gap-1/2">
        {/* Requirement Cards */}
        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">
            Accepted Format
          </p>
          <p className="text-sm mt-1">
            Only JPEG or single-page PDF files are accepted.
          </p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">Color Space</p>
          <p className="text-sm mt-1">
            Use CMYK color space for accurate reproduction.
          </p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">Resolution</p>
          <p className="text-sm mt-1">
            Raster images should have a resolution of at least 150 DPI.
          </p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">File Size</p>
          <p className="text-sm mt-1">Maximum file size is 300MB.</p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">Artwork Size</p>
          <p className="text-sm mt-1">
            Submit artwork at the exact size of your order.
          </p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">
            Crop Marks and Bleeds
          </p>
          <p className="text-sm mt-1">
            Avoid crop marks or bleeds in your file.
          </p>
        </div>

        <div className="bg-gray-800 p-4 rounded-md shadow-sm">
          <p className="text-lg font-semibold text-medium-gold">
            Double-Sided Products
          </p>
          <p className="text-sm mt-1">
            Provide two separate files for double-sided designs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FileUploadInformation;
