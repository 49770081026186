import React, { useContext, createContext, ReactNode, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useUpsertUser from "../hooks/user/useUpsertUser";
import { UserData } from "../interface/user/User";

interface UserContextType {
  user?: UserData; // User might be undefined if not authenticated
}

// Create a context for the user data
const UserContext = createContext<UserContextType>({});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { mutate: upsertUser, isLoading, isError, error } = useUpsertUser();

  useEffect(() => {
    if (isAuthenticated && user) {
      const userData: UserData = {
        email: user.email ?? "",
        name: user.name ?? "",
        picture: user.picture ?? "",
        auth0Id: user.sub ?? "",
      };

      upsertUser(userData);
    }
  }, [isAuthenticated, user, upsertUser, getAccessTokenSilently]);

  // Only provide user data if all fields are defined
  const contextValue = user
    ? {
        user: {
          email: user.email ?? "",
          name: user.name ?? "",
          picture: user.picture ?? "",
          auth0Id: user.sub ?? "",
        },
      }
    : {};

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

// Export useContext Hook for easier consumption
export const useUser = () => useContext(UserContext);
