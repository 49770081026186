import React, { useState, useEffect } from "react";
import { products } from "../../constants/products/products";
import { Product } from "../../interface/product/Product";
import { Link } from "react-router-dom";
import { SearchIcon } from "../../assets/icons/icons";

const ProductSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = products
        .map((product) => ({
          ...product,
          subCategories: product.subCategories?.filter((sub) =>
            sub.name.toLowerCase().includes(searchTerm.toLowerCase())
          ),
        }))
        .filter(
          (product) =>
            (product.subCategories && product.subCategories.length > 0) ||
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm]);

  return (
    <div className="flex-1 min-w-0 px-4">
      <div className="relative w-full max-w-xs">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          name="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white dark:bg-gray-700 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500 dark:text-white dark:focus:border-blue-500 sm:text-sm"
          placeholder="Search products..."
        />
      </div>
      {filteredProducts.length > 0 && (
        <div className="absolute z-10 w-full bg-white shadow-md max-h-60 overflow-auto">
          {filteredProducts.map((product) => (
            <div key={product.name}>
              <Link
                to={product.url}
                className="block px-4 py-2 hover:bg-gray-100 font-bold"
              >
                {product.name}
              </Link>
              {product.subCategories?.map((sub) => (
                <Link
                  key={sub.name}
                  to={product.url + sub.path}
                  className="block pl-8 pr-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {sub.name}
                </Link>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSearch;
