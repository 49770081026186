// src/constants/dimensions.ts

export const maxDimensionsMap: Record<
  string,
  { heightFt: number; heightIn: number; widthFt: number; widthIn: number }
> = {
  "13oz. Vinyl Banner": { heightFt: 9, heightIn: 0, widthFt: 50, widthIn: 0 },
  "10 oz Mesh Banner": { heightFt: 8, heightIn: 11, widthFt: 40, widthIn: 11 },
  "Fabric Backdrop": { heightFt: 12, heightIn: 11, widthFt: 30, widthIn: 11 },
  "Standard Poster": { heightFt: 6, heightIn: 11, widthFt: 4, widthIn: 11 },
  // Add more products and their max dimensions here
};
