import React from "react";
import ChannelLetterProductPage from "../../components/Products/ChannelLetters/ChannelLetterProductPage";
import { findProductByName } from "../../utils/findProductByName";
import { products } from "../../constants/products/products";

const HaloReverseLitProductPage = () => {
  const haloReverseChannelLetterProduct = findProductByName(
    products,
    "Halo/Reverse Lit Channel Letters"
  );

  if (!haloReverseChannelLetterProduct) {
    // Handle the case where the product is not found
    return <div>Product not found.</div>;
  }

  return (
    <ChannelLetterProductPage
      name="Halo/Reverse Lit Channel Letter"
      images={haloReverseChannelLetterProduct.images}
      options={haloReverseChannelLetterProduct.options}
    />
  );
};

export default HaloReverseLitProductPage;
