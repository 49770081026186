import React from "react";
import ProductDisplay from "../../components/Products/ProductDisplay";
import useSubCategoryProducts from "../../hooks/product/useSubCategoryProducts";
import Loading from "../../components/Loading";

const CustomEventTentDisplay = () => {
  const categoryName = "Indoor/Outdoor Displays";
  const subCategoryName = "Custom Event Tents";

  const { data, error, isLoading } = useSubCategoryProducts(
    categoryName,
    subCategoryName
  );

  if (isLoading) {
    return <Loading message="Loading..." />;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  return (
    <ProductDisplay
      title={data.subCategory.name}
      description={data.subCategory.description}
      products={data.products}
    />
  );
};

export default CustomEventTentDisplay;
