import React from "react";
import SignicadeAFrameProductPage from "../../../components/Products/Displays/SignicadeAFrameProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const Simposign = () => {
  const productName = "Simposign II";

  // Fetch the product data by name
  const {
    data: simposignProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = simposignProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!simposignProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <SignicadeAFrameProductPage
      name={simposignProduct.name}
      productOptions={simposignProduct?.productOptions}
      images={presignedUrls ?? []}
      graphic={simposignProduct?.graphic}
      frameColor={simposignProduct?.frameColor}
      path={simposignProduct?.path}
      customSizing={false}
      description={`Frame: Lightweight white frame with recessed areas for drop-in graphics
      Printing Options: Single-sided (front) or double-sided (front and back) display
      Graphics: UV-printed 22" x 28" coroplast graphics for vibrant, weather-resistant signage
      Design: A lightweight and portable option from the Signicade family, perfect for outdoor use`}
    />
  );
};

export default Simposign;
