import React from "react";
import { useNavigate } from "react-router-dom";
import { BackArrowIcon } from "../../assets/icons/icons";

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <button
      onClick={goBack}
      className="flex items-center justify-center p-2 transition duration-150 ease-in-out transform hover:scale-110 text-black-500 hover:text-black-700"
    >
      <BackArrowIcon className="h-6 w-6 mr-2" stroke="currentColor" />
    </button>
  );
};

export default BackButton;
