import React, { useState, FormEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useCustomerOrders from "../../hooks/orders/useCustomerOrders";
import { Order } from "../../interface/orders/order";
import Loading from "../../components/Loading";
import OrderModal from "../../components/Order/OrderModal";
import { PackageIcon } from "../../assets/icons/icons";
import OrderCard from "../../components/Order/OrderCard";
import Pagination from "../../components/Order/Pagination";

const CustomerOrders: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [orderId, setOrderId] = useState<string | undefined>(undefined);
  const [searchOrderId, setSearchOrderId] = useState<string | undefined>(
    undefined
  );
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

  // Only fetch data when searchOrderId is updated
  const { data, isLoading, isError } = useCustomerOrders(
    page,
    5,
    status,
    searchOrderId
  );

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    setPage(1);
    setSearchOrderId(orderId);
  };

  const handleClear = () => {
    setOrderId(undefined);
    setSearchOrderId(undefined);
    setStatus(undefined);
    setPage(1);
  };

  const showOrders = data?.orders.length === 0;

  if (!isAuthenticated) {
    return <p>Please log in to view your orders.</p>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <p>An error occurred while fetching orders. Please try again later.</p>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">Your Orders</h1>
      <form onSubmit={handleSearch} className="mb-4">
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Find by Order ID"
            value={orderId || ""}
            onChange={(e) => setOrderId(e.target.value || undefined)}
            className="border border-gray-300 p-2 rounded"
            aria-label="Find by Order ID"
          />
          <select
            value={status || ""}
            onChange={(e) => setStatus(e.target.value || undefined)}
            className="border border-gray-300 p-2 rounded"
            aria-label="Filter by status"
          >
            <option value="">All</option>
            <option value="new">New</option>
            <option value="in progress">In Progress</option>
            <option value="quoting">Quoting</option>
            <option value="printing">Printing</option>
            <option value="design/artwork">Design/Artwork</option>
            <option value="ready">Ready</option>
            <option value="done">Done</option>
            <option value="cancelled">Cancelled</option>
          </select>
          <button
            type="submit"
            className="bg-deep-blue hover:bg-deep-blue text-white font-bold py-2 px-4 rounded"
          >
            Search
          </button>
          <button
            type="button"
            onClick={handleClear}
            className="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded"
          >
            Clear
          </button>
        </div>
      </form>
      {showOrders ? (
        <div className="flex flex-col items-center justify-center align-middle space-y-6">
          <PackageIcon className="w-24 h-24 text-gray-400" />
          <h1 className="text-3xl font-bold text-gray-800">
            No Orders to Display
          </h1>
        </div>
      ) : (
        <div className="grid gap-6">
          {data?.orders.map((order: Order) => (
            <OrderCard
              key={order.orderId}
              order={order}
              onClick={() => setSelectedOrder(order)}
            />
          ))}
        </div>
      )}
      {!showOrders && (
        <Pagination
          page={page}
          totalPages={data?.totalPages}
          onPrevious={() => setPage((old) => Math.max(old - 1, 1))}
          onNext={() =>
            setPage((old) =>
              !data || !data.totalPages || page === data.totalPages
                ? old
                : old + 1
            )
          }
        />
      )}
      {selectedOrder && (
        <OrderModal
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
    </div>
  );
};

export default CustomerOrders;
