import React from "react";

interface BannerProps {
  message: string;
}

const Banner: React.FC<BannerProps> = ({ message }) => {
  return (
    <div className="bg-dark-gold text-white text-center py-4">
      <p className="text-lg font-semibold">{message}</p>
    </div>
  );
};

export default Banner;
