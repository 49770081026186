import React from "react";
import ChannelLetterProductPage from "../../components/Products/ChannelLetters/ChannelLetterProductPage";
import { products } from "../../constants/products/products";
import { findProductByName } from "../../utils/findProductByName";

const PremiumChannelLetterProductPage = () => {
  const premiumChannelLetterProduct = findProductByName(
    products,
    "Premium Channel Letters"
  );

  if (!premiumChannelLetterProduct) {
    // Handle the case where the product is not found
    return <div>Product not found.</div>;
  }

  return (
    <ChannelLetterProductPage
      name="Premium Channel Letters"
      images={premiumChannelLetterProduct.images}
      options={premiumChannelLetterProduct.options}
    />
  );
};

export default PremiumChannelLetterProductPage;
