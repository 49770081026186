import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAllProducts from "../../hooks/product/useAllProducts";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import Loading from "../../components/Loading";
import { ProductCard } from "../../components/Products/ProductCard";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const Products = () => {
  const navigate = useNavigate();
  const productRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [isLegendOpen, setIsLegendOpen] = useState(false);

  const {
    data: allProducts,
    isLoading: allProductsLoading,
    isError: allProductsError,
  } = useAllProducts();

  const allProductKeys = allProducts?.map((p) => p.images[0]);

  const {
    data: allProductUrls,
    isLoading: allProductUrlsLoading,
    isError: allProductUrlsError,
  } = usePresignedUrls(allProductKeys ?? [], s3Buckets.ProductArtwork);

  useEffect(() => {
    if (allProducts && allProducts.length > 0) {
      allProducts.forEach((product) => {
        const firstLetter = product.name[0].toUpperCase();
        if (!productRefs.current[firstLetter]) {
          productRefs.current[firstLetter] = null;
        }
      });
    }
  }, [allProducts]);

  const handleProductClick = (path: string) => {
    navigate(path);
  };

  const scrollToLetter = (letter: string) => {
    if (productRefs.current[letter]) {
      productRefs.current[letter]?.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (allProductsLoading || allProductUrlsLoading)
    return <Loading message="Loading..." />;
  if (allProductsError || allProductUrlsError)
    return <div>Error loading products</div>;

  return (
    <div className="relative flex min-h-screen">
      <main className="flex-1 p-4 overflow-auto bg-gray-100">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-6 text-center">
            All Products
          </h2>
          {allProducts && allProductUrls && allProducts.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {allProducts.map((product, index) => {
                const firstLetter = product.name[0].toUpperCase();
                return (
                  <div
                    key={index}
                    ref={(el) => {
                      if (!productRefs.current[firstLetter]) {
                        productRefs.current[firstLetter] = el;
                      }
                    }}
                  >
                    <ProductCard
                      product={product}
                      imageUrl={allProductUrls[index]}
                      handleProductClick={handleProductClick}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-center text-gray-500">No products available.</p>
          )}
        </section>
      </main>
      <div
        className={`fixed bottom-8 right-8 z-50 flex items-center justify-center w-16 h-16 bg-deep-blue rounded-full shadow-lg cursor-pointer transition-all duration-300 ${
          isLegendOpen ? "w-64 h-64 p-6" : ""
        }`}
        onClick={() => setIsLegendOpen(!isLegendOpen)}
      >
        {isLegendOpen ? (
          <div className="grid grid-cols-4 gap-2 text-center">
            {alphabet.map((letter) => (
              <button
                key={letter}
                onClick={() => scrollToLetter(letter)}
                className="text-base font-semibold text-white hover:text-dark-gold"
              >
                {letter}
              </button>
            ))}
          </div>
        ) : (
          <span className="text-white text-2xl font-bold">A-Z</span>
        )}
      </div>
    </div>
  );
};

export default Products;
