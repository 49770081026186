import React from "react";
import BannersProductPage from "../../components/Banners/BannersProductPage";
import useProductByName from "../../hooks/product/useProductByName";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";

const FabricBannerBlockout = () => {
  const productName = "Fabric Banner (9.5oz. Blockout)";

  // Fetch the product data by name
  const {
    data: fabricBlockoutBannerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = fabricBlockoutBannerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!fabricBlockoutBannerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannersProductPage
      name="Fabric Banner (9.5oz. Blockout)"
      images={presignedUrls ?? []}
      material={fabricBlockoutBannerProduct?.material}
      polePocket={fabricBlockoutBannerProduct?.polePocket}
      hem={fabricBlockoutBannerProduct?.hem}
      grommet={fabricBlockoutBannerProduct?.grommet}
      velcro={fabricBlockoutBannerProduct?.velcro}
      path={fabricBlockoutBannerProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.FabricBanner}
      description={`9.5 oz polyester banner - Dye sublimated with internal blockout layer
      Designed for applications where backlighting needs to be minimized
      Custom sizes offered with free hemming and grommets included
      Single-sided printing
      Maximum dimensions: 8' x 150' (no oversized prints)
      Optional finishing services available`}
    />
  );
};

export default FabricBannerBlockout;
