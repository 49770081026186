import React from "react";

const Pagination: React.FC<{
  page: number;
  totalPages?: number;
  onPrevious: () => void;
  onNext: () => void;
}> = ({ page, totalPages, onPrevious, onNext }) => (
  <div className="flex justify-between items-center mt-8">
    <button
      onClick={onPrevious}
      disabled={page === 1}
      className="bg-deep-blue hover:bg-deep-blue text-white font-bold py-2 px-4 rounded disabled:bg-gray-400"
    >
      Previous
    </button>
    <span className="text-lg font-medium">
      Page {page} of {totalPages}
    </span>
    <button
      onClick={onNext}
      disabled={!totalPages || page === totalPages}
      className="bg-deep-blue hover:bg-deep-blue text-white font-bold py-2 px-4 rounded disabled:bg-gray-400"
    >
      Next
    </button>
  </div>
);

export default Pagination;
