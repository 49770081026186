import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CartItem {
  product: string;
  productImg?: string;
  options: Record<string, any>;
  total: number;
  productArtwork?: string;
}

interface CartState {
  items: CartItem[];
}

const initialState: CartState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action: PayloadAction<CartItem>) {
      state.items.push(action.payload);
    },
    removeFromCart(state, action: PayloadAction<number>) {
      state.items.splice(action.payload, 1);
    },
    clearCart(state) {
      state.items = [];
    },
    updateProductArtwork(
      state,
      action: PayloadAction<{ index: number; artwork: any }>
    ) {
      const { index, artwork } = action.payload;
      if (state.items[index]) {
        state.items[index].productArtwork = artwork;
      }
    },
  },
});

export const { addToCart, removeFromCart, clearCart, updateProductArtwork } =
  cartSlice.actions;

export default cartSlice.reducer;
