import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProductDisplayProps } from "../../interface/product-display/ProductDisplay";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import Loading from "../Loading";

const ProductDisplay: React.FC<ProductDisplayProps> = ({
  title,
  description,
  products,
}) => {
  const navigate = useNavigate();
  const [productImageKeys, setProductImageKeys] = useState<string[]>([]);

  const {
    data: presignedUrls,
    isLoading,
    isError,
  } = usePresignedUrls(productImageKeys, s3Buckets.ProductArtwork);

  useEffect(() => {
    if (products) {
      // Extract the image keys from the products
      const keys = products.map((product) => product.images?.[0] || "");
      setProductImageKeys(keys);
    }
  }, [products]);

  const handleLinkClick = (path: string) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isLoading) {
    return <Loading message="Loading images..." />;
  }

  if (isError) {
    return <div>Error loading product images</div>;
  }

  return (
    <div className="px-4 py-8">
      <div className="text-center mb-6">
        <h1 className="text-3xl font-bold mb-2">{title}</h1>
        <p className="text-lg font-semibold">{description}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product, index) => (
          <div
            key={index}
            className="flex flex-col bg-white rounded-lg shadow overflow-hidden h-full"
          >
            <img
              src={
                presignedUrls && presignedUrls[index]
                  ? presignedUrls[index]
                  : "/path/to/default-image.jpg"
              }
              alt={product.name}
              className="w-full h-80 object-cover"
            />
            <div className="flex-1 flex flex-col p-6 justify-between">
              <div>
                <h2 className="text-xl font-semibold mb-1">{product.name}</h2>
                <p className="text-sm text-gray-600 mb-4">
                  {product.description}
                </p>
                {product.options?.map((option, optionIndex) => (
                  <div key={optionIndex} className="mb-2">
                    <p className="text-gray-600 font-medium">
                      {option.name}
                      {option.price && ` - $${option.price.toFixed(2)}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 mt-4">
                <button
                  onClick={() => handleLinkClick(product.path)}
                  className="inline-block w-full bg-medium-gold hover:bg-dark-gold text-white font-bold py-2 px-4 rounded text-center"
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDisplay;
