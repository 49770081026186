import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import useCreateCheckoutSession from "../../hooks/stripe/useCreateCheckoutSession";

const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? "";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

interface CartItem {
  product: string;
  productImg?: string;
  options: Record<string, any>;
  total: number;
  productArtwork?: string;
}

interface CheckoutButtonProps {
  cartItems: CartItem[];
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ cartItems }) => {
  const stripe = useStripe();
  const { mutateAsync } = useCreateCheckoutSession();
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const allArtworkUploaded = cartItems.every((item) => {
      if (item.options.requiresArtwork === false) {
        return true; // Product does not require artwork
      }
      return item.productArtwork && item.productArtwork.trim() !== "";
    });
    setButtonDisabled(!allArtworkUploaded);
  }, [cartItems]);

  const handleCheckout = async () => {
    try {
      const { id } = await mutateAsync(cartItems);
      if (!stripe) {
        console.error("Stripe.js has not loaded yet.");
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      if (error) {
        console.error("Error redirecting to checkout:", error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <button
      onClick={handleCheckout}
      disabled={isButtonDisabled}
      className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
        isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      Checkout
    </button>
  );
};

interface StripeContainerProps {
  cartItems: CartItem[];
}

const StripeContainer: React.FC<StripeContainerProps> = ({ cartItems }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutButton cartItems={cartItems} />
    </Elements>
  );
};

export default StripeContainer;
