import React from "react";
import BannerStandsProductPage from "../../../components/Products/Displays/BannerStandsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const TensionFabricStandProductPage = () => {
  const productName = "Tension Fabric Stand";

  // Fetch the product data by name
  const {
    data: tensionFabricStandProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = tensionFabricStandProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!tensionFabricStandProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannerStandsProductPage
      name={tensionFabricStandProduct.name}
      productOptions={tensionFabricStandProduct?.productOptions}
      images={presignedUrls ?? []}
      sizes={tensionFabricStandProduct?.sizes}
      graphic={tensionFabricStandProduct?.graphic}
      path={tensionFabricStandProduct?.path}
      customSizing={false}
      description={`Material: Dye sublimation printing for vibrant and durable pillowcase graphics
      Printing Options: Single or double-sided at the same great price
      Easy Assembly: Tube hardware clicks together without the need for tools
      Reusable: Replaceable graphics allow for year-after-year use
      Extras: Comes with a complimentary carrying case for convenience`}
    />
  );
};

export default TensionFabricStandProductPage;
