import { useMutation, UseMutationResult } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";

interface ContactUsForm {
  name: string;
  email: string;
  category: string;
  orderId?: string;
  message: string;
}

const useSubmitContactUsForm = (): UseMutationResult<
  void,
  AxiosError,
  ContactUsForm
> => {
  const { getAccessTokenSilently } = useAuth0();

  const submitContactUsForm = async (
    formData: ContactUsForm
  ): Promise<void> => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = await getAccessTokenSilently();

    await axios.post(`${apiUrl}/api/users/contact-us`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  return useMutation(submitContactUsForm);
};

export default useSubmitContactUsForm;
