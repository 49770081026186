import React from "react";
import AdvertisingFlagsProductPage from "../../../components/Products/Displays/AdvertisingFlagsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const FeatherAngledFlagProductPage = () => {
  const productName = "Feather Angled Flag";

  // Fetch the product data by name
  const {
    data: featherAngledFlagProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = featherAngledFlagProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!featherAngledFlagProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <AdvertisingFlagsProductPage
      name={featherAngledFlagProduct.name}
      images={presignedUrls ?? []}
      flagOptions={featherAngledFlagProduct?.flagOptions}
      sizes={featherAngledFlagProduct?.sizes}
      graphic={featherAngledFlagProduct?.graphic}
      base={featherAngledFlagProduct?.base}
      carryBag={featherAngledFlagProduct?.carryBag}
      path={featherAngledFlagProduct?.path}
      description={`Printing Options: Single or double-sided
      Material: Dye-sublimated 4 oz polyester mesh for durability and vibrant colors
      Pole Set: Sturdy fiberglass and aluminum construction
      Base Options: Available for both indoor and outdoor use
      Extras: Optional carry bag for easy transport and storage`}
    />
  );
};

export default FeatherAngledFlagProductPage;
