import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const RoundTableCover = () => {
  const productName = "Round Table Cover";

  // Fetch the product data by name
  const {
    data: roundTableCover,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = roundTableCover?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!roundTableCover) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={roundTableCover.name}
      images={presignedUrls ?? []}
      path={roundTableCover?.path}
      tableHeight={roundTableCover?.tableHeight}
      tableDiameter={roundTableCover?.tableDiameter}
      customSizing={false}
      description={`Bar and Standard Height Table Covers
      Material: Made from durable 8.8 oz tension fabric with vibrant full-color dye sublimation printing
      Sizes: Fits tables with diameters of 31.5", 36", 48", or 60"
      Design: Hemmed bottom edge for a polished and professional look
      Versatility: Perfect for bar-height or standard-height tables at events and venues`}
    />
  );
};

export default RoundTableCover;
