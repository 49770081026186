import React, { useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { s3Buckets } from "../../constants/aws/s3";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";

const ShowcaseCarousel: React.FC = () => {
  const autoplay = useRef(
    Autoplay({ delay: 4000, stopOnInteraction: false })
  ).current;
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, align: "center" },
    [autoplay]
  );

  const showcaseArtworkKeys = [
    "showcase-image-2.jpeg",
    "showcase-business-card.jpg",
    "showcase-tent-2.jpg",
  ];

  const {
    data: showcaseArtworkUrls,
    isLoading: showcaseArtworkLoading,
    isError: showcaseArtworkError,
  } = usePresignedUrls(showcaseArtworkKeys, s3Buckets.ShowcaseArtwork);

  if (showcaseArtworkLoading) return <div>Loading Showcase...</div>;
  if (showcaseArtworkError) return <div>Error loading showcase artwork</div>;

  const handlePrev = () => {
    if (emblaApi) emblaApi.scrollPrev(); // Directly call scrollPrev on emblaApi
  };

  const handleNext = () => {
    if (emblaApi) emblaApi.scrollNext(); // Directly call scrollNext on emblaApi
  };

  return (
    <div className="relative max-w-6xl mx-auto">
      <div className="overflow-hidden rounded-lg shadow-lg" ref={emblaRef}>
        <div className="flex">
          {showcaseArtworkUrls &&
            showcaseArtworkUrls.map((url, index) => (
              <div key={index} className="flex-[0_0_100%]">
                <img
                  src={url}
                  alt={`Showcase ${index + 1}`}
                  className="w-full h-[400px] object-cover rounded-lg"
                />
              </div>
            ))}
        </div>
      </div>
      {/* Optional Navigation Buttons */}
      <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-100 p-2 rounded-full shadow-md"
        onClick={handlePrev}
      >
        &#8249;
      </button>
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-100 p-2 rounded-full shadow-md"
        onClick={handleNext}
      >
        &#8250;
      </button>
    </div>
  );
};

export default ShowcaseCarousel;
