import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";

const ReflectiveSignicadeAFrame = () => {
  const productName = "Reflective Signicade A-Frame";

  // Fetch the product data by name
  const {
    data: reflectiveSignicadeAFrameProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = reflectiveSignicadeAFrameProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!reflectiveSignicadeAFrameProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={reflectiveSignicadeAFrameProduct.name}
      images={presignedUrls ?? []}
      graphic={reflectiveSignicadeAFrameProduct?.graphic}
      material={reflectiveSignicadeAFrameProduct?.material}
      frameColor={reflectiveSignicadeAFrameProduct?.frameColor}
      path={reflectiveSignicadeAFrameProduct?.path}
      customSizing={false}
      description={`Genuine Plasticade Signicade with durable white hollow-body construction  
      Supports single or double-sided displays  
      Features UV-printed 24"x36" reflective adhesive vinyl graphics for outdoor-safe use  
      Crafted with 4 mil engineer-grade reflective vinyl for exceptional visibility and durability`}
    />
  );
};

export default ReflectiveSignicadeAFrame;
