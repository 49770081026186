import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, UseMutationResult } from "react-query";
import axios, { AxiosError } from "axios"; // Import AxiosError for better error handling
import { UserData } from "../../interface/user/User";

const useUpsertUser = (): UseMutationResult<UserData, Error, UserData> => {
  const { getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;

  const mutation = useMutation<UserData, Error, UserData>(async (userData) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${apiUrl}/api/users`, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      // Using optional chaining to safely access nested properties
      throw new Error(axiosError.request?.data.error || "An error occurred");
    }
  });

  return mutation;
};

export default useUpsertUser;
