import {
  Product,
  ProductProps,
  SubCategory,
} from "../interface/product/Product";

export const findProductByName = (
  elements: (Product | SubCategory | ProductProps)[],
  productName: string
): ProductProps | null => {
  for (const element of elements) {
    // Type guard to determine if element is a Products
    if ("path" in element && element.name === productName) {
      return element as ProductProps;
    }

    // If the element has subCategories, search within them
    if ("subCategories" in element && element.subCategories) {
      const foundInSubcategories = findProductByName(
        element.subCategories,
        productName
      );
      if (foundInSubcategories) {
        return foundInSubcategories;
      }
    }

    // If the element has products, search within them
    if ("products" in element && element.products) {
      const foundInProducts = findProductByName(element.products, productName);
      if (foundInProducts) {
        return foundInProducts;
      }
    }
  }

  return null;
};
