import React, { useCallback, useEffect, useState } from "react";
import { ProductProps } from "../../interface/product/Product";
import BackButton from "./BackButton";
import ImageGallery from "./ImageGallery";
import OptionSection from "./OptionSection";
import CustomSizeInput from "./CustomSizeInput";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";
import OrderDetails from "./OrderDetails";
import AddToCartButton from "./AddToCartButton";
import ProductDetails from "./ProductDetails";

const tableDiameterMapping: Record<
  string,
  Array<{ name: string; price: number }>
> = {
  '28" Standard height': [
    { name: '31.5" (Overhang 27")', price: 69.0 },
    { name: '36" (Overhang 27")', price: 74.0 },
    { name: '48" (Overhang 25")', price: 79.0 },
    { name: '60" (Overhang 19")', price: 84.0 },
  ],
  '42 " Bar height': [{ name: '31.5" (Overhang 33.25")', price: 79.0 }],
};

const GenericProductPage: React.FC<ProductProps> = ({
  name,
  images,
  productOptions,
  customSizing,
  description,
  reinforcedBRstrip,
  sandbag,
  fullwall,
  halfwall,
  carryBag,
  flagOptions,
  graphic,
  base,
  flagshapeBRsizeoption,
  backside,
  tableHeight,
  tableDiameter,
  sizes,
  runnerHeight,
  runnerWidth,
  solidColorThrowOption,
  requiresArtwork,
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedProductOption, setselectedProductOption] = useState(
    productOptions?.[0] || { name: "", price: 0 }
  );
  const [selectedGraphic, setSelectedGraphic] = useState(
    graphic?.[0] || { name: "", price: 0 }
  );
  const [selectedFlagOptions, setSelectedFlagOptions] = useState(
    flagOptions?.[0] || { name: "", price: 0 }
  );
  const [selectedBase, setSelectedBase] = useState(
    base?.[0] || { name: "", price: 0 }
  );
  const [selectedFlagshapeBRsizeoption, setSelectedFlagshapeBRsizeoption] =
    useState(flagshapeBRsizeoption?.[0] || { name: "", price: 0 });

  const [selectedReinforcedBRstrip, setselectedReinforcedBRstrip] = useState(
    reinforcedBRstrip?.[0] || { name: "", price: 0 }
  );
  const [selectedSandbag, setselectedSandbag] = useState(
    sandbag?.[0] || { name: "", price: 0 }
  );
  const [selectedFullwall, setselectedFullwall] = useState(
    fullwall?.[0] || { name: "", price: 0 }
  );
  const [selectedHalfwall, setselectedHalfwall] = useState(
    halfwall?.[0] || { name: "", price: 0 }
  );
  const [selectedCarrybag, setselectedCarrybag] = useState(
    carryBag?.[0] || { name: "", price: 0 }
  );
  const [selectedBackside, setselectedBackside] = useState(
    backside?.[0] || { name: "", price: 0 }
  );
  const [selectedTableHeight, setselectedTableHeight] = useState(
    tableHeight?.[0] || { name: "", price: 0 }
  );

  const [filteredTableDiameter, setFilteredTableDiameter] = useState(
    tableDiameter || []
  );
  const [selectedTableDiameter, setselectedTableDiameter] = useState(
    tableDiameter?.[0] || { name: "", price: 0 }
  );
  const [selectedSize, setselectedSize] = useState(
    sizes?.[0] || { name: "", price: 0 }
  );
  const [selectedRunnerHeight, setselectedRunnerHeight] = useState(
    runnerHeight?.[0] || { name: "", price: 0 }
  );
  const [selectedRunnerWidth, setselectedRunnerWidth] = useState(
    runnerWidth?.[0] || { name: "", price: 0 }
  );
  const [selectedSolidColorThrowOption, setselectedSolidColorThrowOption] =
    useState(solidColorThrowOption?.[0] || { name: "", price: 0 });

  const [jobName, setJobName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [storePickup, setStorePickup] = useState(false);
  const [total, setTotal] = useState(0);
  const [additionalSquareFootCost, setAdditionalSquareFootCost] = useState(0);
  const [customDimensions, setCustomDimensions] = useState({
    heightFt: 0,
    heightIn: 0,
    widthFt: 0,
    widthIn: 0,
  });

  const onSizeChange = useCallback(
    (
      sqft: number,
      cost: number,
      dimensions: {
        heightFt: number;
        heightIn: number;
        widthFt: number;
        widthIn: number;
      }
    ) => {
      setAdditionalSquareFootCost(cost);
      setCustomDimensions(dimensions);
    },
    []
  );

  const calculateTotalPrice = useCallback(() => {
    const baseTotal =
      (selectedProductOption.price ?? 0) +
      (selectedGraphic.price ?? 0) +
      (selectedFlagOptions.price ?? 0) +
      (selectedBase.price ?? 0) +
      (selectedFlagshapeBRsizeoption.price ?? 0) +
      (selectedReinforcedBRstrip.price ?? 0) +
      (selectedSandbag.price ?? 0) +
      (selectedFullwall.price ?? 0) +
      (selectedHalfwall.price ?? 0) +
      (selectedCarrybag.price ?? 0) +
      (selectedBackside.price ?? 0) +
      (selectedTableHeight.price ?? 0) +
      (selectedTableDiameter.price ?? 0) +
      (selectedSize.price ?? 0) +
      (selectedRunnerHeight.price ?? 0) +
      (selectedRunnerWidth.price ?? 0) +
      (selectedSolidColorThrowOption.price ?? 0) +
      additionalSquareFootCost;
    setTotal(baseTotal * quantity);
  }, [
    selectedProductOption,
    selectedReinforcedBRstrip,
    selectedSandbag,
    selectedFullwall,
    selectedHalfwall,
    selectedCarrybag,
    selectedBase,
    selectedGraphic,
    selectedFlagOptions,
    selectedFlagshapeBRsizeoption,
    selectedBackside,
    selectedTableHeight,
    selectedTableDiameter,
    selectedSize,
    selectedRunnerHeight,
    selectedRunnerWidth,
    selectedSolidColorThrowOption,
    quantity,
    additionalSquareFootCost,
  ]);

  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  useEffect(() => {
    if (tableHeight) {
      const options = tableDiameterMapping[selectedTableHeight.name] || [];
      setFilteredTableDiameter(options);
      setselectedTableDiameter(options[0] || { name: "", price: 0 });
    }
  }, [selectedTableHeight, tableHeight]);

  const productToAdd = {
    product: name,
    productImg: selectedImage,
    options: {
      "Product Option": selectedProductOption.name,
      Graphic: selectedGraphic.name,
      "Job Name": jobName,
      Quantity: quantity,
      "Store Pickup": storePickup === true ? "Yes" : "",
      Dimensions: customDimensions,
      "Reinforced Strip": selectedReinforcedBRstrip.name,
      Sandbag: selectedSandbag.name,
      "Full Wall": selectedFullwall.name,
      "Half Wall": selectedHalfwall.name,
      "Carry Bag": selectedCarrybag.name,
      "Flag Options": selectedFlagOptions.name,
      Base: selectedBase.name,
      "Flag Shape & Size Option": selectedFlagshapeBRsizeoption.name,
      Backside: selectedBackside.name,
      "Table Height": selectedTableHeight.name,
      "Table Diameter": selectedTableDiameter.name,
      Size: selectedSize.name,
      "Runner Height": selectedRunnerHeight.name,
      "Runner Width": selectedRunnerWidth.name,
      "Solid Color Throw Option": selectedSolidColorThrowOption.name,
      requiresArtwork: requiresArtwork,
    },
    total,
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-cool-gray-50 shadow-xl rounded-lg">
      <div className="flex items-center mb-6">
        <BackButton />
        <h1 className="text-3xl font-semibold text-gray-800">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0">
          <ImageGallery
            images={images}
            selectedImage={selectedImage}
            onSelectImage={setSelectedImage}
          />
        </div>
        <div className="md:w-1/2 space-y-4">
          {customSizing && (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <CustomSizeInput
                onSizeChange={onSizeChange}
                pricePerSqFt={pricePerSquareFootMap.Banners}
              />
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-md">
            {productOptions && (
              <OptionSection
                title="Product Option"
                options={productOptions}
                selected={selectedProductOption}
                setSelected={setselectedProductOption}
                displayType="card"
              />
            )}
            {reinforcedBRstrip && (
              <OptionSection
                title="Reinforced Strip"
                options={reinforcedBRstrip}
                selected={selectedReinforcedBRstrip}
                setSelected={setselectedReinforcedBRstrip}
                displayType="dropdown"
              />
            )}
            {sandbag && (
              <OptionSection
                title="Sandbag"
                options={sandbag}
                selected={selectedSandbag}
                setSelected={setselectedSandbag}
                displayType="dropdown"
              />
            )}
            {carryBag && (
              <OptionSection
                title="Carry Bag"
                options={carryBag}
                selected={selectedCarrybag}
                setSelected={setselectedCarrybag}
                displayType="dropdown"
              />
            )}
            {fullwall && (
              <OptionSection
                title="Full Wall"
                options={fullwall}
                selected={selectedFullwall}
                setSelected={setselectedFullwall}
                displayType="dropdown"
              />
            )}
            {halfwall && (
              <OptionSection
                title="Half Wall"
                options={halfwall}
                selected={selectedHalfwall}
                setSelected={setselectedHalfwall}
                displayType="dropdown"
              />
            )}
            {flagshapeBRsizeoption && (
              <OptionSection
                title="Flag Shape & Size Option"
                options={flagshapeBRsizeoption}
                selected={selectedFlagshapeBRsizeoption}
                setSelected={setSelectedFlagshapeBRsizeoption}
                displayType="dropdown"
              />
            )}

            {graphic && (
              <OptionSection
                title="Graphic"
                options={graphic}
                selected={selectedGraphic}
                setSelected={setSelectedGraphic}
                displayType="dropdown"
              />
            )}
            {base && (
              <OptionSection
                title="Base"
                options={base}
                selected={selectedBase}
                setSelected={setSelectedBase}
                displayType="dropdown"
              />
            )}
            {flagOptions && (
              <OptionSection
                title="Flag Holder"
                options={flagOptions}
                selected={selectedFlagOptions}
                setSelected={setSelectedFlagOptions}
                displayType="dropdown"
              />
            )}
            {backside && (
              <OptionSection
                title="Backside"
                options={backside}
                selected={selectedBackside}
                setSelected={setselectedBackside}
                displayType="dropdown"
              />
            )}
            {tableHeight && (
              <OptionSection
                title="Table Height"
                options={tableHeight}
                selected={selectedTableHeight}
                setSelected={setselectedTableHeight}
                displayType="dropdown"
              />
            )}
            {tableDiameter && (
              <OptionSection
                title="Table Diameter"
                options={filteredTableDiameter}
                selected={selectedTableDiameter}
                setSelected={setselectedTableDiameter}
                displayType="dropdown"
              />
            )}
            {sizes && (
              <OptionSection
                title="Size"
                options={sizes}
                selected={selectedSize}
                setSelected={setselectedSize}
                displayType="dropdown"
              />
            )}
            {runnerWidth && (
              <OptionSection
                title="Runner Width"
                options={runnerWidth}
                selected={selectedRunnerWidth}
                setSelected={setselectedRunnerWidth}
                displayType="dropdown"
              />
            )}
            {runnerHeight && (
              <OptionSection
                title="Runner Height"
                options={runnerHeight}
                selected={selectedRunnerHeight}
                setSelected={setselectedRunnerHeight}
                displayType="dropdown"
              />
            )}
            {solidColorThrowOption && (
              <OptionSection
                title="Size and Color"
                options={solidColorThrowOption}
                selected={selectedSolidColorThrowOption}
                setSelected={setselectedSolidColorThrowOption}
                displayType="dropdown"
              />
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <OrderDetails
              jobName={jobName}
              setJobName={setJobName}
              quantity={quantity}
              setQuantity={setQuantity}
              storePickup={storePickup}
              setStorePickup={setStorePickup}
            />
            <AddToCartButton product={productToAdd} />
          </div>
        </div>
      </div>
      <ProductDetails
        details={{
          description:
            description ?? "This is a detailed description of the product...",
          // breakdown: "Here is a breakdown of the components...",
          // warranty: "Warranty information will be detailed here...",
          // faq: [
          //   {
          //     question: "How long is the product warranty?",
          //     answer: "The product comes with a 2-year warranty.",
          //   },
          //   {
          //     question: "Can I install this product myself?",
          //     answer: "Yes, it is designed for easy installation.",
          //   },
          // ],
        }}
      />
    </div>
  );
};

export default GenericProductPage;
