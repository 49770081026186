import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";

const ReflectiveAluminumSign = () => {
  const productName = "Reflective Aluminum Sign";

  // Fetch the product data by name
  const {
    data: reflectiveAluminumSignProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = reflectiveAluminumSignProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!reflectiveAluminumSignProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={reflectiveAluminumSignProduct.name}
      images={presignedUrls ?? []}
      sizes={reflectiveAluminumSignProduct?.sizes}
      numOfSides={reflectiveAluminumSignProduct?.numOfSides}
      holesPunch={reflectiveAluminumSignProduct?.holesPunch}
      corners={reflectiveAluminumSignProduct?.corners}
      material={reflectiveAluminumSignProduct?.material}
      print={reflectiveAluminumSignProduct?.print}
      path={reflectiveAluminumSignProduct?.path}
      customSizing={false}
      description={`.040 aluminum signs available in three sizes: 12"x18", 18"x24", and 36"x24"  
      Single or double-sided printing options for versatile display  
      Durable, rust-proof construction for long-lasting indoor or outdoor use  
      Outdoor-safe UV-printed reflective adhesive vinyl graphics for enhanced visibility  
      Crafted with 4 mil engineer-grade reflective vinyl for superior durability  
      Choose from straight or rounded corner styles`}
    />
  );
};

export default ReflectiveAluminumSign;
