import React from "react";
import {
  HomeIcon,
  PackageIcon,
  ShoppingCartIcon,
  MessageSquareIcon,
  XIcon,
  MenuIcon,
} from "../../../assets/icons/icons";
import { MobileNavigationLink } from ".././MobileNavigationLink";
import { useAuth0 } from "@auth0/auth0-react";

interface MobileMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, toggleMenu }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div>
      <div className="-mr-2 flex items-center sm:hidden">
        <button
          onClick={toggleMenu}
          type="button"
          className="bg-transparent inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 focus:outline-none"
          style={{ marginRight: "1rem" }}
        >
          {isOpen ? (
            <XIcon className="block h-6 w-6" style={{ color: "white" }} />
          ) : (
            <MenuIcon className="block h-6 w-6" style={{ color: "white" }} />
          )}
        </button>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white dark:bg-gray-800 shadow-md">
            {MobileNavigationLink(HomeIcon, "Home")}
            {MobileNavigationLink(MessageSquareIcon, "Contact Us")}
            {isAuthenticated && MobileNavigationLink(PackageIcon, "Orders")}
            {isAuthenticated && MobileNavigationLink(ShoppingCartIcon, "Cart")}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
