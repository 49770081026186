import React from "react";
import LargeFormatProductPage from "../../../../components/Products/LargeFormat/LargeFormatProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../../../constants/products/pricePerSquareFT";

const AdhesiveWallFabric = () => {
  const productName = "Adhesive Wall Fabric";

  // Fetch the product data by name
  const {
    data: adhesiveWallFabricProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = adhesiveWallFabricProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!adhesiveWallFabricProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <LargeFormatProductPage
      name={adhesiveWallFabricProduct.name}
      images={presignedUrls ?? []}
      material={adhesiveWallFabricProduct?.material}
      print={adhesiveWallFabricProduct?.print}
      path={adhesiveWallFabricProduct?.path}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.AdhesiveWallFabric}
      description={`6.5 mil wall fabric, comparable to Phototex and WallFrog  
      Easy to install—fabric resists creasing and stretching unlike vinyl  
      Specialty adhesive allows for effortless removal and repositioning  
      Ideal for temporary wall decals or full wall murals  
      UV-printed for a durable and vibrant finish`}
    />
  );
};

export default AdhesiveWallFabric;
