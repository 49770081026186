import React from "react";
import WallArtProductPage from "../../../../components/Products/LargeFormat/WallArtProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";

const FramedPoster = () => {
  const productName = "Framed Poster";

  // Fetch the product data by name
  const {
    data: framedPosterProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = framedPosterProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!framedPosterProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <WallArtProductPage
      name={framedPosterProduct.name}
      sizes={framedPosterProduct?.sizes}
      images={presignedUrls ?? []}
      frameColor={framedPosterProduct?.frameColor}
      path={framedPosterProduct?.path}
      customSizing={false}
      description={`Complete classic frame kit designed for a 16" x 24" poster print
      Includes a vibrant full-color print, frame, mat board, and clear acrylic glazing
      Arrives ready to hang straight out of the box
      Final dimensions, including the frame, are 23" x 31"`}
    />
  );
};

export default FramedPoster;
