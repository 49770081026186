import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";
import { ProductProps } from "../../interface/product/Product";

// Define the structure for SubCategoryProps
export interface SubCategoryProps {
  _id: string;
  name: string;
  description: string;
  path: string;
  products: ProductProps[];
}

// Define the structure of the response that includes both subCategory and products
interface SubCategoryProductsResponse {
  subCategory: SubCategoryProps;
  products: ProductProps[];
}

const fetchSubCategoryProducts = async (
  categoryName: string,
  subCategoryName: string
): Promise<SubCategoryProductsResponse> => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const response = await axios.get(`${apiUrl}/api/product`, {
    params: {
      categoryName,
      subCategoryName,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useSubCategoryProducts = (
  categoryName: string,
  subCategoryName: string
): UseQueryResult<SubCategoryProductsResponse, AxiosError> => {
  return useQuery(
    ["subCategoryProducts", categoryName, subCategoryName],
    () => fetchSubCategoryProducts(categoryName, subCategoryName),
    {
      keepPreviousData: true,
      staleTime: 600000,
      onError: (error) => {
        console.error("Failed to fetch subcategory products:", error.message);
      },
    }
  );
};

export default useSubCategoryProducts;
