import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const SolidColorTableThrow = () => {
  const productName = "Solid Color Table Throws";

  // Fetch the product data by name
  const {
    data: solidColorTableThrow,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = solidColorTableThrow?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!solidColorTableThrow) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={solidColorTableThrow.name}
      images={presignedUrls ?? []}
      path={solidColorTableThrow?.path}
      solidColorThrowOption={solidColorTableThrow?.solidColorThrowOption}
      customSizing={false}
      requiresArtwork={solidColorTableThrow?.requiresArtwork}
      description={`7 oz Factory-Dyed Table Covers
      Material: Durable 7 oz fabric in a variety of factory-dyed colors
      Sizing: Designed to fit 6' or 8' tables perfectly
      Color Options: Multiple colors available to match your event theme
      Note: Printing is not available for this product`}
    />
  );
};

export default SolidColorTableThrow;
