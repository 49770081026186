import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProductProps } from "../../interface/product/Product";
import BackButton from "../Products/BackButton";
import ImageGallery from "../Products/ImageGallery";
import OptionSection from "../Products/OptionSection";
import CustomSizeInput from "../Products/CustomSizeInput";
import OrderDetails from "../Products/OrderDetails";
import AddToCartButton from "../Products/AddToCartButton";
import ProductDetails from "../Products/ProductDetails";
import { maxDimensionsMap } from "../../constants/products/maxDimenstionsMap";

const BannersProductPage: React.FC<ProductProps> = ({
  name,
  images,
  bannerOptions,
  sizes,
  graphic,
  numOfSides,
  material,
  path,
  polePocket,
  hem,
  grommet,
  customSizing,
  windslit,
  webbing,
  corners,
  rope,
  colorProfile,
  velcro,
  pricePerSqFt,
  description,
}) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [selectedBannerOption, setselectedBannerOption] = useState(
    bannerOptions?.[0] || { name: "", price: 0 }
  );
  const [selectedSize, setSelectedSize] = useState(
    sizes?.[0] || { name: "", price: 0 }
  );
  const [selectedGraphic, setSelectedGraphic] = useState(
    graphic?.[0] || { name: "", price: 0 }
  );
  const [selectedNumOfSides, setselectedNumOfSides] = useState(
    numOfSides?.[0] || { name: "", price: 0 }
  );
  const [selectedMaterial, setselectedMaterial] = useState(
    material?.[0] || { name: "", price: 0 }
  );
  const [selectedPolePocket, setselectedPolePocket] = useState(
    polePocket?.[0] || { name: "", price: 0 }
  );
  const [selectedHem, setselectedHem] = useState(
    hem?.[0] || { name: "", price: 0 }
  );
  const [selectedGrommet, setselectedGrommet] = useState(
    grommet?.[0] || { name: "", price: 0 }
  );
  const [selectedWindslit, setselectedWindslit] = useState(
    windslit?.[0] || { name: "", price: 0 }
  );
  const [selectedWebbing, setselectedWebbing] = useState(
    webbing?.[0] || { name: "", price: 0 }
  );
  const [selectedCorners, setselectedCorners] = useState(
    corners?.[0] || { name: "", price: 0 }
  );
  const [selectedRope, setselectedRope] = useState(
    rope?.[0] || { name: "", price: 0 }
  );
  const [selectedColorProfile, setselectedColorProfile] = useState(
    colorProfile?.[0] || { name: "", price: 0 }
  );
  const [selectedVelcro, setselectedVelcro] = useState(
    velcro?.[0] || { name: "", price: 0 }
  );
  const [jobName, setJobName] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [storePickup, setStorePickup] = useState(false);
  const [total, setTotal] = useState(0);
  const [additionalSquareFootCost, setAdditionalSquareFootCost] = useState(0);
  const [customDimensions, setCustomDimensions] = useState({
    heightFt: 0,
    heightIn: 0,
    widthFt: 0,
    widthIn: 0,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const expandableRef = useRef<HTMLDivElement>(null);

  const maxDimensions = maxDimensionsMap[name]; // Fetch max dimensions from the map
  console.log("🚀 ~ maxDimensions:", maxDimensions);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (expandableRef.current) {
      if (isExpanded) {
        setContentHeight(expandableRef.current.scrollHeight); // Set the height to the actual content height when expanded
      } else {
        setContentHeight(0); // Set the height to 0 when collapsed
      }
    }
  }, [isExpanded]);

  const onSizeChange = useCallback(
    (
      sqft: number,
      cost: number,
      dimensions: {
        heightFt: number;
        heightIn: number;
        widthFt: number;
        widthIn: number;
      }
    ) => {
      setAdditionalSquareFootCost(cost);
      setCustomDimensions(dimensions);
    },
    []
  );

  const calculateTotalPrice = useCallback(() => {
    const baseTotal =
      (selectedBannerOption.price ?? 0) +
      (selectedSize.price ?? 0) +
      (selectedGraphic.price ?? 0) +
      (selectedMaterial.price ?? 0) +
      (selectedPolePocket.price ?? 0) +
      (selectedHem.price ?? 0) +
      (selectedNumOfSides.price ?? 0) +
      (selectedGrommet.price ?? 0) +
      (selectedWindslit.price ?? 0) +
      (selectedWebbing.price ?? 0) +
      (selectedCorners.price ?? 0) +
      (selectedRope.price ?? 0) +
      (selectedColorProfile.price ?? 0) +
      (selectedVelcro.price ?? 0) +
      additionalSquareFootCost;

    // Check conditions to determine if the price needs to be doubled
    const isDoublePrice = selectedNumOfSides.name === "2 Sides";
    // Apply doubling logic
    const finalTotal = isDoublePrice ? baseTotal * 2 : baseTotal;

    setTotal(finalTotal * quantity);
  }, [
    selectedBannerOption,
    selectedSize,
    selectedGraphic,
    selectedMaterial,
    selectedPolePocket,
    selectedHem,
    selectedNumOfSides,
    selectedGrommet,
    selectedWindslit,
    selectedWebbing,
    selectedCorners,
    selectedRope,
    selectedColorProfile,
    selectedVelcro,
    quantity,
    additionalSquareFootCost,
  ]);

  useEffect(() => {
    calculateTotalPrice();
  }, [calculateTotalPrice]);

  const productToAdd = {
    product: name,
    productImg: selectedImage,
    options: {
      "Banner Option": selectedBannerOption.name,
      Size: selectedSize.name,
      Graphic: selectedGraphic.name,
      "Number of Sides": selectedNumOfSides.name,
      Material: selectedMaterial.name,
      "Pole Pocket": selectedPolePocket.name,
      Hem: selectedHem.name,
      Grommet: selectedGrommet.name,
      Windslit: selectedWindslit.name,
      Webbing: selectedWebbing.name,
      Corners: selectedCorners.name,
      Rope: selectedRope.name,
      "Color Profile": selectedColorProfile.name,
      Velcro: selectedVelcro.name,
      "Job Name": jobName,
      Quantity: quantity,
      "Store Pickup": storePickup === true ? "Yes" : "",
      Dimensions: customDimensions,
    },
    total,
  };

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-cool-gray-50 shadow-xl rounded-lg">
      <div className="flex items-center mb-6">
        <BackButton />
        <h1 className="text-3xl font-semibold text-gray-800">{name}</h1>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0">
          <ImageGallery
            images={images}
            selectedImage={selectedImage}
            onSelectImage={setSelectedImage}
          />
        </div>
        <div className="md:w-1/2 space-y-4">
          {customSizing && (
            <div className="bg-white p-6 rounded-lg shadow-md">
              <CustomSizeInput
                onSizeChange={onSizeChange}
                pricePerSqFt={pricePerSqFt ?? 1}
                maxDimensions={maxDimensions}
              />
            </div>
          )}

          <div className="bg-white p-6 rounded-lg shadow-md">
            {/* First 4 options are always displayed */}
            {bannerOptions && (
              <OptionSection
                title="Banner Option"
                options={bannerOptions}
                selected={selectedBannerOption}
                setSelected={setselectedBannerOption}
                displayType="card"
              />
            )}
            {material && (
              <OptionSection
                title="Material"
                options={material}
                selected={selectedMaterial}
                setSelected={setselectedMaterial}
                displayType="dropdown"
              />
            )}
            {sizes && (
              <OptionSection
                title="Size"
                options={sizes}
                selected={selectedSize}
                setSelected={setSelectedSize}
                displayType="dropdown"
              />
            )}
            {graphic && (
              <OptionSection
                title="Graphic"
                options={graphic}
                selected={selectedGraphic}
                setSelected={setSelectedGraphic}
                displayType="dropdown"
              />
            )}
            {numOfSides && (
              <OptionSection
                title="# Of Sides"
                options={numOfSides}
                selected={selectedNumOfSides}
                setSelected={setselectedNumOfSides}
                displayType="dropdown"
              />
            )}

            {hem && (
              <OptionSection
                title="Hem"
                options={hem}
                selected={selectedHem}
                setSelected={setselectedHem}
                displayType="dropdown"
              />
            )}
            {grommet && (
              <OptionSection
                title="Grommet"
                options={grommet}
                selected={selectedGrommet}
                setSelected={setselectedGrommet}
                displayType="dropdown"
              />
            )}

            {/* Collapsible section with smooth height transition */}
            <div className="relative">
              <div
                ref={expandableRef}
                style={{
                  maxHeight: `${contentHeight}px`,
                  overflow: "hidden",
                  transition: "max-height 0.3s ease",
                }}
              >
                {polePocket && (
                  <OptionSection
                    title="Pole Pocket"
                    options={polePocket}
                    selected={selectedPolePocket}
                    setSelected={setselectedPolePocket}
                    displayType="dropdown"
                  />
                )}
                {windslit && (
                  <OptionSection
                    title="Windslit"
                    options={windslit}
                    selected={selectedWindslit}
                    setSelected={setselectedWindslit}
                    displayType="dropdown"
                  />
                )}
                {webbing && (
                  <OptionSection
                    title="Webbing"
                    options={webbing}
                    selected={selectedWebbing}
                    setSelected={setselectedWebbing}
                    displayType="dropdown"
                  />
                )}
                {corners && (
                  <OptionSection
                    title="Corners"
                    options={corners}
                    selected={selectedCorners}
                    setSelected={setselectedCorners}
                    displayType="dropdown"
                  />
                )}
                {rope && (
                  <OptionSection
                    title="Rope"
                    options={rope}
                    selected={selectedRope}
                    setSelected={setselectedRope}
                    displayType="dropdown"
                  />
                )}
                {colorProfile && (
                  <OptionSection
                    title="Color Profile"
                    options={colorProfile}
                    selected={selectedColorProfile}
                    setSelected={setselectedColorProfile}
                    displayType="dropdown"
                  />
                )}
                {velcro && (
                  <OptionSection
                    title="Velcro"
                    options={velcro}
                    selected={selectedVelcro}
                    setSelected={setselectedVelcro}
                    displayType="dropdown"
                  />
                )}
              </div>

              {/* Toggle button for showing/hiding additional options */}
              <div className="mt-4">
                <button
                  onClick={toggleExpanded}
                  className="text-blue-500 hover:underline"
                >
                  {isExpanded ? "Show Less Options" : "Show More Options"}
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md mt-4">
            <OrderDetails
              jobName={jobName}
              setJobName={setJobName}
              quantity={quantity}
              setQuantity={setQuantity}
              storePickup={storePickup}
              setStorePickup={setStorePickup}
            />
            <AddToCartButton product={productToAdd} />
          </div>
        </div>
      </div>
      <ProductDetails
        details={{
          description: description ?? "",
          // returns: `All sales are final. We do not accept returns or provide refunds for any purchases.
          // For any questions or concerns about your order, please fill out our **Contact Us** form, and a member of our team will be happy to assist you.`,
          // warranty: "Warranty information will be detailed here...",
          // faq: [
          //   {
          //     question: "How long is the product warranty?",
          //     answer: "The product comes with a 2-year warranty.",
          //   },
          //   {
          //     question: "Can I install this product myself?",
          //     answer: "Yes, it is designed for easy installation.",
          //   },
          // ],
        }}
      />
    </div>
  );
};

export default BannersProductPage;
