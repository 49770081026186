import React from "react";

interface ImageGalleryProps {
  images: string[];
  selectedImage: string;
  onSelectImage: (image: string) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  selectedImage,
  onSelectImage,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <img
          alt="Selected Example"
          src={selectedImage}
          className="rounded-lg shadow-lg border border-gray-300 w-full object-fit"
          style={{ height: "75vh" }} // Adjust height as needed
          loading="lazy"
        />
      </div>
      <div className="w-full flex flex-wrap justify-center gap-2">
        {images.map((img, index) => (
          <button
            key={index}
            className={`border-2 ${
              img === selectedImage ? "border-blue-500" : "border-gray-200"
            } rounded-lg overflow-hidden transition-colors duration-300 ease-in-out`}
            onClick={() => onSelectImage(img)}
            style={{ width: "100px", height: "100px" }} // Adjust thumbnail size as needed
          >
            <img
              alt={`Thumbnail ${index + 1}`}
              className="w-full h-full object-cover"
              src={img}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
