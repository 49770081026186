import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearCart } from "../../store/cartSlice";

const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearCart());

    const timer = setTimeout(() => {
      navigate("/");
    }, 5000); // Redirects to home page after 5 seconds

    return () => clearTimeout(timer);
  }, [navigate, dispatch]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-10 rounded-lg shadow-lg text-center">
        <svg
          className="mx-auto mb-4 h-16 w-16 text-green-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2l4-4m0 0l-4 4m4-4L9 7"
          />
        </svg>
        <h1 className="text-4xl font-semibold text-green-600 mb-4">
          Payment Successful!
        </h1>
        <p className="text-gray-800 mb-4">Thank you for your purchase.</p>
        <p className="text-gray-600 mb-8">
          You will be redirected to the homepage shortly.
        </p>
        <button
          onClick={() => navigate("/")}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200"
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default Success;
