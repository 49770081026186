import React from "react";
import WallArtProductPage from "../../../../components/Products/LargeFormat/WallArtProductPage";
import useProductByName from "../../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../../constants/aws/s3";

const FramedPrints = () => {
  const productName = "Framed Prints";

  // Fetch the product data by name
  const {
    data: framedPrintsProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = framedPrintsProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!framedPrintsProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <WallArtProductPage
      name={framedPrintsProduct.name}
      sizes={framedPrintsProduct?.sizes}
      images={presignedUrls ?? []}
      frame={framedPrintsProduct?.frame}
      path={framedPrintsProduct?.path}
      customSizing={false}
      description={`Lightweight polystyrene frame with the appearance of wood  
      Vivid, full-color print mounted on foamcore for a sleek, clean finish  
      Comes ready to hang with an included installation kit  
      Available sizes: 16"x20", 18"x24", 24"x32"`}
    />
  );
};

export default FramedPrints;
