import React from "react";
import BannersProductPage from "../../components/Banners/BannersProductPage";
import useProductByName from "../../hooks/product/useProductByName";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { pricePerSquareFootMap } from "../../constants/products/pricePerSquareFT";

const ThirteenOzVinylBannerProductPage = () => {
  const productName = "13oz Vinyl Banner";

  // Fetch the product data by name
  const {
    data: thirteenOzVinylBannerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = thirteenOzVinylBannerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!thirteenOzVinylBannerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <BannersProductPage
      name="13oz. Vinyl Banner"
      images={presignedUrls ?? []}
      numOfSides={thirteenOzVinylBannerProduct?.numOfSides}
      material={thirteenOzVinylBannerProduct?.material}
      polePocket={thirteenOzVinylBannerProduct?.polePocket}
      hem={thirteenOzVinylBannerProduct?.hem}
      grommet={thirteenOzVinylBannerProduct?.grommet}
      windslit={thirteenOzVinylBannerProduct?.windslit}
      webbing={thirteenOzVinylBannerProduct?.webbing}
      // corners={thirteenOzVinylBannerProduct?.corners}
      path={thirteenOzVinylBannerProduct?.path}
      rope={thirteenOzVinylBannerProduct?.rope}
      customSizing={true}
      pricePerSqFt={pricePerSquareFootMap.ThirteenozVinylBanner}
      description={`13 oz matte scrim banner  
      UV full-color printing for vivid, long-lasting results  
      Designed for both indoor and outdoor use  
      Hemming and grommets included at no extra cost  
      Optional finishing services available  
      Oversized banners with welded edges offered`}
    />
  );
};

export default ThirteenOzVinylBannerProductPage;
