import React from "react";
import Banner from "../../components/Home/Banner";
import useTopProducts from "../../hooks/product/useTopProducts";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import usePresignedUrls from "../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../constants/aws/s3";
import { ProductCard } from "../../components/Products/ProductCard";
import ShowcaseCarousel from "../../components/Home/ShowcaseCarousel";

const Home = () => {
  const navigate = useNavigate();
  const {
    data: topProducts,
    isLoading: topProductsLoading,
    isError: topProductsError,
  } = useTopProducts();

  const topProductKeys = topProducts?.map((p) => p.images[0]);

  const {
    data: topProductUrls,
    isLoading: topProductUrlsLoading,
    isError: topProductUrlsError,
  } = usePresignedUrls(topProductKeys ?? [], s3Buckets.ProductArtwork);

  const handleProductClick = (path: string) => {
    navigate(path);
  };

  if (topProductsLoading || topProductUrlsLoading)
    return <Loading message="Loading..." />;
  if (topProductsError || topProductUrlsError)
    return <div>Error loading products</div>;

  return (
    <div className="flex flex-col min-h-screen">
      <Banner message="Welcome to Our H-Marketing Shop! Check out our latest offers." />
      <main className="flex-1 p-4 overflow-auto bg-gray-100">
        <section className="mb-8">
          <ShowcaseCarousel />
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Top Products</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {topProducts &&
              topProductUrls &&
              topProducts.map((product, index) => (
                <ProductCard
                  key={index}
                  product={product}
                  imageUrl={topProductUrls[index]}
                  handleProductClick={handleProductClick}
                />
              ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;
