import React from "react";
import AFrameProductPage from "../../../components/Products/Displays/AFrameProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const SnapPosterHangerProductPage = () => {
  const productName = "Snap Poster Hanger";

  // Fetch the product data by name
  const {
    data: snapPosterHangerProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = snapPosterHangerProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!snapPosterHangerProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <AFrameProductPage
      name={snapPosterHangerProduct.name}
      productOptions={snapPosterHangerProduct?.productOptions}
      images={presignedUrls ?? []}
      graphic={snapPosterHangerProduct?.graphic}
      sizes={snapPosterHangerProduct?.sizes}
      material={snapPosterHangerProduct?.material}
      path={snapPosterHangerProduct?.path}
      customSizing={false}
      description={`Secure Grip: Aluminum rails hold graphics firmly in place
      Versatile Mounting: Hanging tabs compatible with suction cups, wires, or nails
      Design: Two-piece set holds graphics at the top and bottom for stability
      Compatibility: Works with any poster material`}
    />
  );
};

export default SnapPosterHangerProductPage;
