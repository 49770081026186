import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const EightFeetTableCover = () => {
  const productName = "8ft Table Cover";

  // Fetch the product data by name
  const {
    data: eightftTableCover,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = eightftTableCover?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!eightftTableCover) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={eightftTableCover.name}
      images={presignedUrls ?? []}
      backside={eightftTableCover?.backside}
      path={eightftTableCover?.path}
      customSizing={false}
      description={`8.8 oz Tension Fabric Table Cover
          Material: High-quality 8.8 oz tension fabric with full-color dye sublimation printing
          Sizing: Designed to fit standard 8' tables perfectly
          Back Options: Open-back style for easy access and comfort when seated behind the table, or closed-back for full 360-degree coverage
          Use Case: Ideal for exhibitions, trade shows, and events`}
    />
  );
};

export default EightFeetTableCover;
