import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { products } from "../../constants/products/products";
import { ForwardArrowIcon, closedArrow } from "../../assets/icons/icons";

const LeftNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleLinkClick = (path: string) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* Toggle button for small screens */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`md:hidden fixed z-50 m-4 p-2 bg-blue-500 text-white rounded transition-transform ${
          isOpen ? "translate-x-64" : "translate-x-0"
        }`}
        aria-label="Toggle navigation"
        style={{
          top: "50%",
          left: "0",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {isOpen ? "×" : closedArrow}
      </button>

      {/* Navigation Panel */}
      <div
        className={`fixed inset-y-0 left-0 z-40 w-64 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out bg-white shadow-xl md:translate-x-0 md:static md:inset-auto overflow-y-auto`}
      >
        <ul className="list-none p-0 m-0">
          {products.map((product) => (
            <li key={product.name} className="border-b border-gray-200">
              <div className="px-4 py-3 font-bold text-gray-800 bg-gray-100">
                {product.name}
              </div>
              <ul className="pl-4 bg-white">
                {product.subCategories?.map((sub) => (
                  <li key={sub.name}>
                    <button
                      onClick={() => handleLinkClick(product.url + sub.path)}
                      className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left flex items-center justify-between"
                    >
                      {sub.name}
                      {sub.products && sub.products.length > 0 && (
                        <ForwardArrowIcon className="ml-2 text-gray-500" />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
              <ul className="pl-4 bg-white">
                {product.products?.map((sub) => (
                  <li key={sub.name}>
                    <button
                      onClick={() => handleLinkClick(sub.path)}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      {sub.name}
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LeftNavBar;
