import React from "react";

interface LoadingProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message = "Loading..." }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white bg-opacity-90">
      <div className="relative w-16 h-16">
        <div className="absolute top-0 left-0 w-full h-full bg-deep-blue opacity-60 rounded-full animate-bounce delay-0"></div>
        <div className="absolute top-0 left-0 w-full h-full bg-deep-blue opacity-60 rounded-full animate-bounce delay-1000"></div>
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-800">{message}</p>
    </div>
  );
};

export default Loading;
