import React from "react";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";
import GenericProductPage from "../../../components/Products/GenericProductPage";

const TableRunner = () => {
  const productName = "Table Runner";

  // Fetch the product data by name
  const {
    data: tableRunner,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = tableRunner?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!tableRunner) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <GenericProductPage
      name={tableRunner.name}
      images={presignedUrls ?? []}
      path={tableRunner?.path}
      productOptions={tableRunner?.productOptions}
      runnerWidth={tableRunner?.runnerWidth}
      runnerHeight={tableRunner?.runnerHeight}
      customSizing={false}
      description={`8.8 oz Tension Fabric Hemmed Table Runner
      Material: Durable 8.8 oz tension fabric with vibrant full-color dye sublimation for all-over printing
      Customization: Available in custom sizes to suit your branding needs
      Washable: Reusable and easy to maintain for long-term use
      Cost-Effective: An economical way to brand solid table throws at events and exhibitions`}
    />
  );
};

export default TableRunner;
