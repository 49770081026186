import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";
import { ProductProps } from "../../interface/product/Product";

const fetchTopProducts = async (): Promise<ProductProps[]> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  // const token = await getAccessTokenSilently();

  const response = await axios.get(`${apiUrl}/api/product/top-products`, {
    headers: {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

const useTopProducts = (): UseQueryResult<ProductProps[], AxiosError> => {
  // const { getAccessTokenSilently } = useAuth0();

  return useQuery("topProducts", () => fetchTopProducts(), {
    keepPreviousData: true,
  });
};

export default useTopProducts;
