import React, { useState } from "react";
import { Order } from "../../interface/orders/order";

interface OrderModalProps {
  order: Order | null;
  onClose: () => void;
}

const OrderModal: React.FC<OrderModalProps> = ({ order, onClose }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  if (!order) return null;

  const renderOptions = (options: Record<string, any>) => {
    return (
      <ul className="list-disc list-inside space-y-1">
        {Object.entries(options)
          .filter(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              return Object.values(value).some((v) => v);
            }
            return value !== "" || key === "Store Pickup";
          })
          .map(([key, value]) => (
            <li key={key} className="text-gray-600">
              <span className="font-semibold">{key}:</span>{" "}
              {key === "Store Pickup"
                ? value === true
                  ? "Yes"
                  : "No"
                : typeof value === "object"
                ? renderDimensions(value)
                : value}
            </li>
          ))}
      </ul>
    );
  };

  const renderDimensions = (dimensions: {
    heightFt: number;
    heightIn: number;
    widthFt: number;
    widthIn: number;
  }) => {
    const { heightFt, heightIn, widthFt, widthIn } = dimensions;
    if (!heightFt && !heightIn && !widthFt && !widthIn) return null;
    return (
      <span>
        {heightFt}ft {heightIn}in x {widthFt}ft {widthIn}in
      </span>
    );
  };

  const handleNext = () => {
    setActiveItemIndex((current) => (current + 1) % order.items.length);
  };

  const handlePrevious = () => {
    setActiveItemIndex(
      (current) => (current - 1 + order.items.length) % order.items.length
    );
  };

  const activeItem = order.items[activeItemIndex];
  const multipleItems = order.items.length > 1;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white p-6 rounded-lg shadow-md z-50 w-full max-w-2xl mx-auto flex justify-between items-center">
        {multipleItems && (
          <button
            onClick={handlePrevious}
            className="p-1 rounded-full shadow-lg text-xl"
          >
            &lt;
          </button>
        )}
        <div className="flex flex-col w-full px-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">
              Order ID: {order.orderId}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-900"
            >
              Close
            </button>
          </div>
          <div className="flex items-center justify-between mb-4">
            <p className="text-gray-600">
              <strong>Total:</strong> ${order.totalAmount.toFixed(2)}
            </p>
            <p className="text-gray-600">
              <strong>Created At:</strong>{" "}
              {new Date(order.createdAt).toLocaleString()}
            </p>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-semibold text-gray-700">Item:</h3>
            <ul className="list-none ml-4 mt-2">
              <li className="text-gray-600">
                <span className="font-medium">{activeItem.product}</span> -
                Quantity: {activeItem.quantity}, Total: $
                {activeItem.total.toFixed(2)}
                <div className="mt-2">{renderOptions(activeItem.options)}</div>
              </li>
            </ul>
          </div>
          <div className="flex justify-center mt-4">
            {order.items.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full mx-1 ${
                  index === activeItemIndex ? "bg-blue-500" : "bg-gray-300"
                }`}
              ></div>
            ))}
          </div>
        </div>
        {multipleItems && (
          <button
            onClick={handleNext}
            className="p-1 rounded-full shadow-lg text-xl"
          >
            &gt;
          </button>
        )}
      </div>
    </div>
  );
};

export default OrderModal;
