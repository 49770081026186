import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";
import { OrdersResponse } from "../../interface/orders/order";

const fetchCustomerOrders = async (
  page: number,
  limit: number,
  status: string | undefined,
  orderId: string | undefined,
  getAccessTokenSilently: Function
): Promise<OrdersResponse> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = await getAccessTokenSilently();

  const response = await axios.get(
    `${apiUrl}/api/orders/customer-orders?page=${page}&limit=${limit}${
      status ? `&status=${status}` : ""
    }${orderId ? `&orderId=${orderId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

const useCustomerOrders = (
  page: number,
  limit: number = 5,
  status?: string,
  orderId?: string
): UseQueryResult<OrdersResponse, AxiosError> => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery(
    ["customerOrders", page, status, orderId],
    () =>
      fetchCustomerOrders(page, limit, status, orderId, getAccessTokenSilently),
    {
      keepPreviousData: true,
    }
  );
};

export default useCustomerOrders;
