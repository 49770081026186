import React from "react";
import AdvertisingFlagsProductPage from "../../../components/Products/Displays/AdvertisingFlagsProductPage";
import useProductByName from "../../../hooks/product/useProductByName";
import usePresignedUrls from "../../../hooks/aws/usePresignedUrls";
import { s3Buckets } from "../../../constants/aws/s3";

const FeatherConvexFlagProductPage = () => {
  const productName = "Feather Convex Flag";

  // Fetch the product data by name
  const {
    data: featherConvexFlagProduct,
    isLoading: productLoading,
    isError: productError,
    error: productErrorMessage,
  } = useProductByName(productName, {
    staleTime: 5 * 60 * 1000, // Cache product data for 5 minutes
  });

  // Extract image keys after product data is fetched
  const imageKeys = featherConvexFlagProduct?.images ?? [];

  // Fetch the presigned URLs only if image keys exist
  const {
    data: presignedUrls,
    isLoading: urlsLoading,
    isError: urlsError,
  } = usePresignedUrls(imageKeys, s3Buckets.ProductArtwork, {
    enabled: !!imageKeys.length, // Only fetch URLs when we have image keys
    staleTime: 10 * 60 * 1000, // Cache URLs for 10 minutes
  });

  if (productLoading || urlsLoading) {
    return <div>Loading product and images...</div>;
  }

  if (productError || urlsError) {
    return (
      <div>
        Error fetching product or images:{" "}
        {productErrorMessage?.message || "Error occurred."}
      </div>
    );
  }

  if (!featherConvexFlagProduct) {
    return <div>Product not found.</div>;
  }

  // Pass presigned URLs to the component once they are ready
  return (
    <AdvertisingFlagsProductPage
      name={featherConvexFlagProduct.name}
      images={presignedUrls ?? []}
      flagOptions={featherConvexFlagProduct?.flagOptions}
      sizes={featherConvexFlagProduct?.sizes}
      graphic={featherConvexFlagProduct?.graphic}
      base={featherConvexFlagProduct?.base}
      carryBag={featherConvexFlagProduct?.carryBag}
      path={featherConvexFlagProduct?.path}
      description={`Printing Options: Single or double-sided designs
      Material: Durable 4 oz polyester mesh, dye-sublimated for vibrant, long-lasting colors
      Pole Set: High-quality fiberglass and aluminum construction
      Base Options: Suitable for both indoor and outdoor setups
      Extras: Optional carry bag for convenient transport and storage`}
    />
  );
};

export default FeatherConvexFlagProductPage;
