import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Auth0Provider
            domain={AUTH0_DOMAIN as string}
            clientId={AUTH0_CLIENT_ID as string}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
              audience: `https://${AUTH0_DOMAIN}/api/v2/`,
              redirect_uri: window.location.origin,
              // Ensure the scope includes openid which is required for ID tokens.
              scope: "openid profile email",
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <App />
            </QueryClientProvider>
          </Auth0Provider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
